import React, { useState } from 'react';
import { Search, ArrowUpDown, Trash2 } from 'lucide-react';
import img1 from "./assets/images/cafe.png"
import img2 from './assets/images/imagestatic1.png'
import img3 from "./assets/images/restfav.png"
import logo1 from "./assets/images/loogfinal.png"
import logo2 from "./assets/images/avatar3.png"


const PlacesToVisitPage = () => {
  // Sample places data
  const initialPlacesData = [
    {
      id: 1,
      image: img1,
      name: "Nobu Restaurant",
      businessLogo: logo1,
      businessName: "Nobu Hospitality",
      city: "New York",
      category:"Eid 1",
      reviewCount: 245,
      rating: 95,
    },
    {
      id: 2,
      image: img2,
      name: "The French Laundry",
      businessLogo: logo2,
      businessName: "Thomas Keller Restaurant Group",
      city: "Napa Valley",
      category:"Eid 2",
      reviewCount: 200,
      rating: 75,
    },
    {
      id: 3,
      image: img3,
      name: "Eleven Madison Park",
      businessLogo: logo1,
      businessName: "Make It Nice",
      city: "New York",
      category:"Independance Day",
      reviewCount: 195,
      rating: 85,
    }
  ];

  // State management
  const [placesData, setPlacesData] = useState(initialPlacesData);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  // Delete handler
  const handleDelete = (id) => {
    const updatedPlaces = placesData.filter(place => place.id !== id);
    setPlacesData(updatedPlaces);
  };

  // Sorting handler
  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...placesData];

    switch (option) {
      case 'name-asc':
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name-desc':
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'city-asc':
        sortedData.sort((a, b) => a.city.localeCompare(b.city));
        break;
      case 'city-desc':
        sortedData.sort((a, b) => b.city.localeCompare(a.city));
        break;
      default:
        break;
    }

    setPlacesData(sortedData);
  };

  // Filter places
  const filteredPlaces = placesData.filter(place =>
    place.name.toLowerCase().includes(nameFilter.toLowerCase()) ||
    place.city.toLowerCase().includes(nameFilter.toLowerCase())
  );

  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      {/* Filters Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Name/City Search */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search Places
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name or city"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Sort Options */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="">Select Filter</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="city-asc">City (A-Z)</option>
              <option value="city-desc">City (Z-A)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>

      {/* Places Table */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-white mb-4">My Places to Visit</h2>
        <table className="min-w-full">
          <thead className="hidden sm:table-header-group">
            <tr className="bg-[#1a2942]">
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Place</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Business </th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Category </th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Reviews</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Rating</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Delete From List</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {filteredPlaces.map((place) => (
              <tr key={place.id} className="hover:bg-[#1a2942] flex flex-col sm:table-row mb-4 sm:mb-0">
                <td className="px-4 sm:px-6 py-4 flex items-center">
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 rounded-lg overflow-hidden">
                      <img src={place.image} alt={place.name} className="w-full h-full object-cover" />
                    </div>
                    <div>
                      <p className="font-['GeneralSansRegular'] text-white">{place.name}</p>
                    </div>
                  </div>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-lg overflow-hidden">
                        <img 
                          src={place.businessLogo} 
                         
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <span className="font-['GeneralSansRegular'] text-white">{place.businessName}</span>
                    </div>
                  
                  <p className="font-['GeneralSansRegular'] ml-10 text-xs text-white">{place.city}</p>
                  
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                <p className="font-['GeneralSansRegular'] text-white">{place.category}</p>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-white">{place.reviewCount} <span className="sm:hidden">Reviews</span></span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <div className="w-full bg-[#DC3DF7] rounded-full h-2">
                    <div 
                      className="bg-[#3DE9DC] h-2 rounded-full" 
                      style={{ width: `${place.rating}%` }}
                    ></div>
                  </div>
                  <span className="text-white text-sm ml-2 font-['GeneralSansRegular']">{place.rating}%</span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <button
                    onClick={() => handleDelete(place.id)}
                    className="text-red-500 hover:text-red-400 transition-colors"
                  >
                    <Trash2 size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* No Results Message */}
        {filteredPlaces.length === 0 && (
          <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
            No places found matching your search.
          </div>
        )}
      </div>
    </div>
  );
};

export default PlacesToVisitPage;