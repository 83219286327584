import React, { useState } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ChangePasswordScreen = () => {
  const navigate = useNavigate();
  
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handlePasswordChange = () => {
    // Add password change logic here
    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match");
      return;
    }
    
    // Placeholder for actual password change logic
    alert('Password change functionality to be implemented');
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#4D6F8F] pb-20">
      {/* Header with Back Button */}
      <div className="flex justify-between items-center p-4 pt-20 pb-10">

      </div>

      {/* Change Password Title */}
   

      {/* Password Change Section */}
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-6 font-['GeneralSansMedium']">Update Password</h2>
        
        {/* Input Fields */}
        <div className="space-y-4">
          {/* Current Password Input */}
          <div className="relative">
            <input 
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter current password"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Current Password</label>
          </div>

          {/* New Password Input */}
          <div className="relative">
            <input 
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">New Password</label>
          </div>

          {/* Confirm New Password Input */}
          <div className="relative">
            <input 
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Confirm new password"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Confirm New Password</label>
          </div>
        </div>

        <button className="bg-[#B7BDCA] mt-10 p-4 rounded-lg w-full">
        <span className="text-[#0D182E] text-base font-bold font-['GeneralSansMedium']">
          Change Password
        </span>
      </button>
      </div>
    </div>
  );
};

export default ChangePasswordScreen;