import React, { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer ,
  RadialBarChart,
  RadialBar
} from 'recharts';
import logo2 from "./assets/images/logoauthentic.png";


import { useNavigate } from 'react-router-dom';
import mapicon from "./assets/images/Layer 2.png"
import logo from "./assets/images/loogfinal.png"; 
import authink from "./assets/images/logoauthentic.png";
import leftImage from "./assets/images/hand_mobile.png";
import rightImage from "./assets/images/referral_image.png";
import logoauth from "./assets/images/logoauthentic.png"
import reviewsIcon from "./assets/images/Existing Review.png";
import profile from "./assets/images/profile.png";
import profileImage from "./assets/images/hardworkingman.jpeg";
import companyLogo1 from "./assets/images/logoauthentic.png";
import companyLogo2 from "./assets/images/company-logo2.png";
import workIcon from "./assets/images/workicon.png";
import SmallDial from './SmallDial';
const performanceData = [
  { name: '12 Months', value: 100, reviews: 1200 },
  { name: '30 Days', value: 85, reviews: 350 },
  { name: '7 Days', value: 10, reviews: 80 },
];

const ProfessionalDetailPage = () => {
  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState('12 months');
  const [currentPerformance, setCurrentPerformance] = useState(performanceData[0]);

  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
 
  
  const chartData = {
    '12 months': [
      { month: 'Jan', reviews: 40, referrals: 24 },
      { month: 'Feb', reviews: 35, referrals: 28 },
      { month: 'Mar', reviews: 45, referrals: 32 },
      { month: 'Apr', reviews: 50, referrals: 36 },
      { month: 'May', reviews: 42, referrals: 30 },
      { month: 'Jun', reviews: 48, referrals: 35 }
    ],
    '30 days': [
      { month: 'Week 1', reviews: 15, referrals: 8 },
      { month: 'Week 2', reviews: 18, referrals: 10 },
      { month: 'Week 3', reviews: 22, referrals: 12 },
      { month: 'Week 4', reviews: 25, referrals: 15 }
    ],
    '7 days': [
      { month: 'Day 1', reviews: 5, referrals: 3 },
      { month: 'Day 2', reviews: 6, referrals: 4 },
      { month: 'Day 3', reviews: 7, referrals: 5 },
      { month: 'Day 4', reviews: 8, referrals: 6 },
      { month: 'Day 5', reviews: 9, referrals: 7 },
      { month: 'Day 6', reviews: 10, referrals: 8 },
      { month: 'Day 7', reviews: 11, referrals: 9 }
    ]
  };
  const progressData = [
    { label: 'Responsiveness', value: 75 },
    { label: 'Professionalism', value: 60 },
    { label: 'Expertise', value: 90 },
    { label: 'Extra Care', value: 60 },

  ];

  const reviewsData = [
    {
      id: 1,
      name: 'Floyd Miles',
      subtitle: '147 Reviews 23 Referrals - Brand Ambassador',
      date: '1 Month Ago',
      customerExperience: '99/100',
      review: 'First of all I want to start off by Thanking the team at Authentink as everyone was always super friendly and helpful with our enquiries and booking. However a HUGE Thank you to Mertim, who not only did an absolutely amazing job with my tattoo but I genuinely walked out of the studio feeling like I had made a great friend.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      subtitle: '82 Reviews 15 Referrals - Bronze Ambassador',
      date: '2 Months Ago',
      customerExperience: '95/100',
      review: 'An incredible experience from start to finish. The attention to detail and professionalism was outstanding. I would highly recommend this service to anyone looking for top-quality work.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    },
    {
      id: 3,
      name: 'Michael Chen',
      subtitle: '65 Reviews 10 Referrals - Silver Ambassador',
      date: '3 Months Ago',
      customerExperience: '92/100',
      review: 'A fantastic service that exceeded my expectations. The team was knowledgeable, friendly, and truly passionate about what they do. Im looking forward to working with them again.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    }
  ];

  const PerformanceBar = ({ label, value, percentage }) => (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
      <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
      </div>
      <div className="flex items-center space-x-4 w-full sm:w-3/4">
        <div className="flex-grow bg-gray-700 h-3 rounded-full">
          <div 
            className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
          {value}/10
        </span>
      </div>
    </div>
  );

  
  return (
    <div className="w-full bg-[#4D6F8F] p-4 sm:p-8 space-y-4 sm:space-y-8 mt-20" style={{fontFamily: 'GeneralSansRegular'}}>
      {/* Image Container */}
      <div className="border-2 sm:border-4 border-white rounded-xl overflow-hidden appearance-none">
        <div className="relative w-full h-48 sm:h-96 appearance-none">
          <img 
            src={profileImage} 
            alt="Professional Profile" 
            className="w-full h-full object-cover appearance-none"
          />
          
          {/* Name and Designation Container */}
          <div className="absolute bottom-4 sm:bottom-8 left-4 sm:left-8 flex items-center space-x-2 sm:space-x-4">
            <img 
              src={authink} 
              alt="Profile Icon" 
              className=" h-5 sm:h-10  w-32  sm:w-52"
            />
            
          </div>
        </div>
      </div>
      <div className='flex flex-row'>
      <div className="text-white">
              <h2 className="text-lg sm:text-2xl font-bold">Marco Smith</h2>
             
              <p className="text-sm sm:text-base">Full-time Tattoo Artist</p>
            </div>
            <div className=' w-full h-full '>

            </div>
            </div>
      

      <div className=" ">
        <p className="text-white text-base sm:text-xl">
        Marco Smith comes to us from Europe where he has been tattooing in all styles for the past 28 years but  has been mainly focusing on studying black & grey tattooing from sleeves and backs to to one See more. loves doing neo-traditional tattoos of girl heads, traditional Japanese, animals and related imagery. Horivida also has a natural gift for realism and is passionate about creating this style of tattoo in both small and large format.
        He is a natural artist that will most likely draw your tattoo right in front of you during the consult, he doesn’t mess around with long wait times. Once you book in he’ll have your artwork ready to go very quickly and you’ll get the awesome tattoo you’ve been looking for!
        </p>
      </div>
      <section className="bg-[#0D182E] rounded-2xl p-6 sm:p-8">
        <div className="flex items-center mb-6">
          <img src={workIcon} alt="Work Experience" className="w-8 h-8 mr-4" />
          <h2 className="text-2xl sm:text-3xl font-[VerminViva] text-white">Work Experience</h2>
        </div>

        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-[#1a2942] rounded-lg p-2 flex flex-col items-center">
            <img 
              src={companyLogo1} 
              alt="Company 1" 
              className="w-24 h-24 object-contain "
            />
            <h3 className="text-white text-lg font-semibold">Authentic/Ink</h3>
            <p className="text-gray-400 text-xs mt-2">
              June 2018 - Present
            </p>
          </div>

          <div className="bg-[#1a2942] rounded-lg p-4 flex flex-col items-center">
            <img 
              src={companyLogo2} 
              alt="Company 2" 
              className="w-24 h-24 object-contain"
            />
            <h3 className="text-white text-lg font-semibold">Sacred Skin</h3>
            <p className="text-gray-400 text-xs mt-2">
              January 2015 - May 2018
            </p>
          </div>

        </div>
      </section>
      <div className='w-full flex flex-col md:flex-row'>
  <div className="w-full md:w-[80%]">
    {progressData.map((item, index) => (
      <div key={index} className="mb-4">
        <div className="flex justify-between mb-2">
          <span className="font-[GeneralSansMedium] text-[#0D182E]">{item.label}</span>
          <span className="font-[GeneralSansMedium] text-[#0D182E]">{item.value}</span>
        </div>
        <div className="w-full bg-[#DC3DF7] rounded-full h-3">
          <div 
            className="bg-[#3DE9DC] h-3 rounded-full" 
            style={{width: `${item.value}%`}}
          ></div>
        </div>
      </div>
    ))}
  </div>
  <div className="w-full md:w-1/4 px-4 md:pl-20 mt-6 md:mt-0">
    <img 
      src={mapicon} 
      alt="Large Profile" 
      className="w-full h-auto max-w-[200px] mx-auto md:max-w-none"
    />
  </div>
</div>
        <div className="w-full bg-slate-900 py-12 px-8 rounded-2xl border-8 border-emerald-500">
  <div className="w-full mx-auto flex flex-col lg:flex-row gap-8">
    {/* Left Side - Radial Chart Section */}
    <div className="w-full lg:w-1/2">
      <div className="relative h-[400px]">
        {/* Logo near dial origin */}
        <div className="absolute top-1/2 left-1/2 pr-14 pb-4 transform -translate-x-1/2 -translate-y-1/2 z-10">
          <img
            src={logo}
            alt="Company Logo"
            className="w-10 h-10 object-contain"
          />
        </div>

        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart 
            innerRadius="70%" 
            outerRadius="90%" 
            data={[currentPerformance]} 
            startAngle={180} 
            endAngle={0}
          >
            {/* Customer Experience Dial */}
            <RadialBar 
              minAngle={15} 
              background 
              clockWise 
              dataKey="value" 
              cornerRadius={14}
              fill="#DC3DF7"
              outerRadius="40%"
            />
            
            {/* Reviews Dial */}
            <RadialBar 
              minAngle={15} 
              background 
              clockWise 
              dataKey="value" 
              cornerRadius={14}
              fill="#3DE9DC"
            />
          </RadialBarChart>
        </ResponsiveContainer>
        
        {/* Value Labels inside radial bars */}
        <div className="absolute top-1/2 left-1/4 sm:pt-5 sm:pl-28  pt-5 text-center">
          <div className="text-xs font-['GeneralSansBold'] text-[#DC3DF7]">
            Culture <span className="text-white text-sm">{currentPerformance.value}</span>
          </div>
        </div>
        
        <div className="absolute top-1/2 right-1/4 sm:right-1/3 pt-5  sm:pr-10 text-center">
          <div className="text-xs font-['GeneralSansBold'] text-[#3DE9DC]">
            Reviews <span className="text-white text-sm">{currentPerformance.value}</span>
          </div>
        </div>

        {/* Custom Needle */}
        <div 
          className="absolute top-36 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1 h-24 sm:h-28 bg-white origin-bottom"
          style={{
            transform: `translate(-50%, -50%) rotate(${270 + (currentPerformance.value * 180 / 100)}deg)`,
            boxShadow: '0 0 10px rgba(255,255,255,0.3)',
            transition: 'transform 1.5s ease-out'
          }}
        >
          <div 
            className="absolute bottom-0 left-1/2 -translate-x-1/2 w-4 h-4 bg-white border-2 border-[#DC3DF7] rounded-full"
            style={{
              transition: 'all 0.5s ease-out'
            }}
          />
        </div>
      </div>

      {/* Performance Tabs - Now properly aligned below the radial chart */}
      <div className="mt-8">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
          {performanceData.map((perf) => (
            <button
              key={perf.name}
              onClick={() => setCurrentPerformance(perf)}
              className={`p-3 text-left rounded-lg transition-all duration-300 flex justify-between items-center shadow-md 
                ${currentPerformance.name === perf.name 
                  ? 'bg-gradient-to-r from-[#3DE9DC] to-[#3DE9DC]/80 text-[white] font-bold' 
                  : 'bg-[#1E2A44] text-slate-300 hover:bg-[#2C3E5A] hover:shadow-xl'
              }`}
            >
              <span className="text-base font-['GeneralSansMedium']">{perf.name}</span>
              <span className="text-sm font-['GeneralSansBold'] bg-[#0D182E]/50 px-2 py-1 rounded-full">{perf.reviews}</span>
            </button>
          ))}
        </div>
      </div>
    </div>

    {/* Right Side - Bar Chart Section */}
    <div className="w-full lg:w-1/2">
      <div className="flex flex-col h-full">
        {/* Chart Container */}
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData[selectedPeriod]}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid 
                horizontal={true} 
                vertical={false} 
                stroke="rgba(255,255,255,0.1)" 
              />
              <XAxis 
                dataKey="month" 
                tick={{ fill: 'white' }} 
                axisLine={{ stroke: 'white' }}
              />
              <YAxis 
                tick={{ fill: 'white' }} 
                axisLine={{ stroke: 'white' }}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: '#0D182E', 
                  color: 'white',
                  border: '1px solid white'
                }} 
              />
              <Legend 
                wrapperStyle={{ color: 'white' }} 
                iconType="circle"
              />
              <Bar 
                dataKey="reviews" 
                fill="#DC3DF7" 
                name="Reviews" 
                barSize={35}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Period Selection Tabs - Now properly aligned below the bar chart */}
        <div className="mt-8">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            {['12 months', '30 days', '7 days'].map((period) => (
              <button
                key={period}
                onClick={() => setSelectedPeriod(period)}
                className={`py-3 text-white text-lg rounded-lg transition-all duration-300 ${
                  selectedPeriod === period 
                    ? 'bg-purple-600 font-bold' 
                    : 'bg-slate-900 hover:bg-slate-800'
                }`}
              >
                {period}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      <section className="mb-8 bg-[#0D182E] rounded-lg shadow-md p-4 sm:p-6 m-6">
        <div className="flex items-center mb-4">
          <img src={reviewsIcon} alt="Reviews" className="w-8 h-8 mr-4" />
          <h2 className="text-xl sm:text-2xl  font-[VerminViva] text-white">Exsisting Reviews</h2>

        </div>
       


        {/* Reviews Carousel Container */}
        <div className="relative w-full overflow-hidden">
          {/* Navigation Buttons */}
          {currentReviewIndex > 0 && (
            <button 
              onClick={() => setCurrentReviewIndex(currentReviewIndex - 1)}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-[#527DE6] text-white p-2 rounded-full"
            >
              &lt;
            </button>
          )}
          {currentReviewIndex < reviewsData.length - 1 && (
            <button 
              onClick={() => setCurrentReviewIndex(currentReviewIndex + 1)}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-[#527DE6] text-white p-2 rounded-full"
            >
              &gt;
            </button>
          )}

          {/* Reviews Content */}
          <div 
            className="w-full transition-transform duration-300 ease-in-out"
            style={{ 
              transform: `translateX(-${currentReviewIndex * 100}%)`,
            }}
          >
            <div className="flex">
              {reviewsData.map((review) => (
                <div 
                  key={review.id} 
                  className="w-full flex-shrink-0 bg-[#1a2942] rounded-lg shadow-md p-6"
                >
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-4">
                    <div className="flex items-start space-x-4 mb-2 sm:mb-0">
                      <div className="w-12 h-12 rounded-full overflow-hidden flex-shrink-0">
                        <img
                          src={profile}
                          alt="Profile"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div>
                        <h3 className="font-['GeneralSansBold'] text-white text-lg">{review.name}</h3>
                        <p className="font-['GeneralSansRegular'] text-gray-300 text-sm">
                          {review.subtitle}
                        </p>
                      </div>
                    </div>
                    <span className="font-['GeneralSansRegular'] text-gray-300 text-sm">{review.date}</span>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-6 space-y-4 sm:space-y-0">
                  <SmallDial currentPerformance={{ value:70 }} />
                    <img
                      src={logo2}
                      alt="Authentic"
                      className="w-28 sm:w-36 h-8 sm:h-10"
                    />
                  </div>

                  <div className="mb-4">
                    <h4 className="text-white font-['GeneralSansBold'] text-base sm:text-lg mb-2">
                      Customer Experience: {review.customerExperience}
                    </h4>
                  </div>

                  <p className="font-['GeneralSansRegular'] text-gray-300 mb-6 text-sm sm:text-base">
                    {review.review}
                  </p>

                  <div className="space-y-2">
                    {review.performanceBars.map((bar, index) => (
                      <PerformanceBar 
                        key={index} 
                        label={bar.label} 
                        value={bar.value} 
                        percentage={bar.percentage} 
                      />
                    ))}
                  </div>
                  <div className="text-center m-1 mt-3">

        <button 
          className="px-2 py-3 rounded-lg font-['GeneralSansBold'] text-white text-base"
          style={{ backgroundColor: '#B7BDCA',color :"#0D182E" }}
        >
          Report    
        </button>
      </div>
                </div>
                
              ))}
              
            </div>

        
          </div>

          {/* Navigation Dots */}
          <div className="flex justify-center mt-4 space-x-2">
            {reviewsData.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentReviewIndex(index)}
                className={`
                  w-3 h-3 rounded-full 
                  ${currentReviewIndex === index ? 'bg-[#527DE6]' : 'bg-gray-300'}
                `}
              />
            ))}
          </div>
        </div>
      </section>


    </div>
  );
};

export default ProfessionalDetailPage;