import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secimage from "./assets/images/worldchart.png"
import maleImage from "./assets/images/maleimage.png";
import femaleImage from "./assets/images/femaleimage.png";
import { TrendingUp, Users, DollarSign, Star, ShoppingCart, Activity, Clock, Eye, Bookmark, Share, File ,Zap, UserCheck2} from 'lucide-react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, RadialBar, RadialBarChart,
   AreaChart, Area, XAxis, YAxis, CartesianGrid, BarChart,Bar } from 'recharts';
import logo from "./assets/images/loogfinal.png"
import logo2 from "./assets/images/maleimage.png"
import team1 from "./assets/images/teammember.jpeg";
import team2 from "./assets/images/teammember1.jpeg";
import team3 from "./assets/images/teammember2.png";
import team4 from "./assets/images/teammember3.jpeg";

   const generateData = () => {
    const data = [];
    const startDate = new Date('2012-01-01');
    const endDate = new Date('2013-02-27');
    
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 3)) {
      const baseValue = 20 + (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 7);
      const reviewRandomFactor = Math.random() * 2 - 1;
      const referralRandomFactor = Math.random() * 2 - 1;
      data.push({
        date: date.getTime(),
        reviews: parseFloat((baseValue + reviewRandomFactor).toFixed(2)),
        referrals: parseFloat((baseValue * 0.7 + referralRandomFactor).toFixed(2)) // Referrals slightly lower than reviews
      });
    }
    return data;
  };
  
  const sampleData = generateData();

  const CustomCursor = () => null;
const donutData = [
  { name: 'Male', value: 60 },
  { name: 'Female', value: 30 },
  { name: 'Others', value: 10 }
];

  const ageData = [
    { label: '10-18', value: 45 },
    { label: '19-24', value: 78 },
    { label: '25-34', value: 92 },
    { label: '35-44', value: 65 },
    { label: '45-54', value: 48 },
    { label: '55-64', value: 35 },
    { label: '65+', value: 22 }
  ];

const donutDatanew = [
  { name: 'Cash Back Reviews', value: 45 },
  { name: 'Revenue', value: 80 },
  { name: 'Cash Back Referrals', value: 75 },
  { name: 'Relationship Revenue', value: 30 },

];

const COLORS = ['#527DE6 ', '#DC3DF7', '#65D4B0', '#7D4CF0'];


const performanceData = [
  { name: '12 Months', value: 100, reviews: 1200 },
  { name: '30 Days', value: 85, reviews: 350 },
  { name: '7 Days', value: 10, reviews: 80 },
];

const progressData = [
  { lable: 'Price vs Value', value: 87 },
  { lable: 'Delivery Time', value: 82 },
  { lable: 'Business Layout', value: 92 },
  { lable: 'Quality', value: 92 },
  { lable: 'Performance', value: 85 },
  { lable: 'Easy To Use', value: 54 },
  { lable: 'Durability', value: 55 },
  { lable: 'Expertise', value: 89 },
  { lable: 'Responsiveness', value: 71 },
  { lable: 'Professionalism', value: 61 },
  { lable: 'ExtraCare', value: 61 }
];
const StatCard = ({ icon: Icon, title, value, description }) => (
  <div className="bg-[#0D182E] p-4 sm:p-6 rounded-lg h-full">
    <div className="flex items-center gap-4 mb-2">
      <div className="p-2 sm:p-3 bg-transparent border-2 rounded-full">
        <Icon className="w-4 h-4 sm:w-6 sm:h-6 text-white" />
      </div>
      <h3 className="text-sm sm:text-lg font-['GeneralSansSemibold'] text-slate-100">{title}</h3>
    </div>
    <p className="text-xl sm:text-3xl font-['GeneralSansMedium'] text-[#DC3DF7] mb-1">{value}</p>
  </div>
);

const employees = [
  {
    id: 1,
    name: "Sarah Johnson",
    image: team1,
   stats: [
      { metric: "Responsiveness", value: 85 },
      { metric: "ExtraCare", value: 90 },
      { metric: "Expertise", value: 78 },
      { metric: "Professionalism", value: 95 }
    ]
  },
  {
    id: 2,
    name: "Mike Chen",
    image: team2,
    stats: [
      { metric: "Responsiveness", value: 88 },
      { metric: "ExtraCare", value: 82 },
      { metric: "Expertise", value: 92 },
      { metric: "Professionalism", value: 87 }
    ]
  },
  {
    id: 3,
    name: "Emma Davis",
    image: team3,
    stats: [
      { metric: "Responsiveness", value: 92 },
      { metric: "ExtraCare", value: 88 },
      { metric: "Expertise", value: 85 },
      { metric: "Professionalism", value: 90 }
    ]
  },
  {
    id: 4,
    name: "Lewis Hamilton",
    image: team4,
    stats: [
      { metric: "Responsiveness", value: 42 },
      { metric: "ExtraCare", value: 88 },
      { metric: "Expertise", value: 75 },
      { metric: "Professionalism", value: 90 }
    ]
  }
];


const BusinessAnalytics = () => {
  const [currentPerformance, setCurrentPerformance] = useState(performanceData[0]);
   
  const [selectedEmployee, setSelectedEmployee] = useState(employees[0]);
  const [timeRange, setTimeRange] = useState('1 Week');

  // Custom shape for rounded bars
const RoundedBar = (props) => {
  const { fill, x, y, width, height } = props;

  const isTopBar = props.dataKey === "referrals";
  const radius = isTopBar ? 4 : 0;

  return (
    <path
      d={`
        M ${x},${y + height}
        L ${x},${y + radius}
        Q ${x},${y} ${x + radius},${y}
        L ${x + width - radius},${y}
        Q ${x + width},${y} ${x + width},${y + radius}
        L ${x + width},${y + height}
        L ${x},${y + height}
        Z
      `}
      fill={fill}
    />
  );
};

  const generateData = (range) => {
    const data = [];
    const today = new Date();
    const periods = range === '1 Year' ? 12 : range === '1 Month' ? 30 : 7;
    
    for (let i = 0; i < periods; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      data.unshift({
        date: date.getTime(),
        reviews: Math.floor(Math.random() * 50) + 10,
        referrals: Math.floor(Math.random() * 40) + 5,
        pageVisits: Math.floor(Math.random() * 200) + 50
      });
    }
    return data;
  };

  const filterData = (range) => {
    return generateData(range);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return timeRange === '1 Year' 
      ? date.toLocaleDateString('en-US', { month: 'short' })
      : date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const formatTooltipDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <div className="min-h-screen pt-10 px-4 sm:px-6 lg:px-8 mb-10">
     <div className="bg-[#65D4B0]  p-5 mt-10 rounded-xl">
        <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-[#0D182E] mb-2">Analytics Overview</h2>
        <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 mb-6  rounded-2xl'>
       <StatCard 
                     icon={Bookmark}
                     title="Reviews"
                     value="85"
                     description="Increase in monthly active users"
                   />
                   <StatCard 
                     icon={Users}
                     title="Refferals"
                     value="2,345"
                     description="Users active in the last 30 days"
                   />
                   <StatCard 
                     icon={DollarSign}
                     title="Revenue"
                     value="$12,345"
                     description="Total revenue this month"
                   />
                   <StatCard 
                     icon={Eye}
                     title="Review Pay Profile Visits"
                     value="110"
                     description="Average rating from users"
                   />
                   <StatCard 
                     icon={ShoppingCart}
                     title="My Shop List"
                     value="1,234"
                     description="Total orders this month"
                   />
                   <StatCard 
                     icon={Clock}
                     title="Days Since SignUp"
                     value="111"
                     description="User engagement rate"
                   />
                   <StatCard 
                     icon={Share}
                     title="Business Web Page Clicks"
                     value="58"
                     description="User engagement rate"
                   />
                   <StatCard 
                     icon={File}
                     title="Expenses"
                     value="$1000"
                     description="User engagement rate"
                   />
                    <StatCard 
           icon={UserCheck2}
         title="Users Place to Visit"
         value="42"
         description="People who follow your business"
       />
        </div>
      </div>


      <div className="w-full space-y-6 mt-10">
          {/* Time Series Chart */}
          <div className="w-95 p-6 rounded-lg bg-[#0D182E]">
      <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Reviews Vs Referrals & Visits</h2>
      <div className="h-px w-full bg-slate-700 mb-4" />
      
      <div className="mb-4 flex gap-2">
        {['1 Year', '1 Month', '1 Week'].map((range) => (
          <button
            key={range}
            onClick={() => setTimeRange(range)}
            className={`px-3 py-1 rounded font-['GeneralSansMedium'] ${
              timeRange === range
                ? 'bg-emerald-400 text-slate-900'
                : 'bg-slate-800 text-slate-300 hover:bg-slate-700'
            }`}
          >
            {range}
          </button>
        ))}
      </div>
      
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={filterData(timeRange)}
            margin={{ top: 10, right: 30, left: 10, bottom: 0 }}
            barSize={20}
          >
            <CartesianGrid 
              horizontal={true}
              vertical={false}
              stroke="#1E2A44"
            />
            <XAxis
              dataKey="date"
              tickFormatter={formatDate}
              type="number"
              domain={['dataMin', 'dataMax']}
              stroke="#4B5563"
              tick={{ 
                fontFamily: 'GeneralSansRegular',
                fontSize: 12
              }}
              axisLine={{ stroke: '#1E2A44' }}
            />
            <YAxis 
              stroke="#4B5563"
              tick={{ 
                fontFamily: 'GeneralSansRegular',
                fontSize: 12
              }}
              axisLine={{ stroke: '#1E2A44' }}
              tickLine={{ stroke: '#1E2A44' }}
            />
            <Tooltip
            cursor={<CustomCursor />}
              labelFormatter={formatTooltipDate}
              formatter={(value, name) => [`${value}`, name]}
              contentStyle={{ 
                background: '#1E2A44', 
                border: 'none', 
                borderRadius: '4px',
                fontFamily: 'GeneralSansRegular',
                fontSize: 12
              }}
              labelStyle={{ 
                color: '#9CA3AF',
                fontFamily: 'GeneralSansRegular'
              }}
            />
            <Legend 
              wrapperStyle={{
                fontFamily: 'GeneralSansRegular',
                fontSize: 12
              }}
            />
            <Bar 
              dataKey="pageVisits" 
              stackId="a" 
              fill="#4B5EE3"
              name="Page Visits"
              shape={<RoundedBar />}
            />
            <Bar 
              dataKey="reviews" 
              stackId="a" 
              fill="#65D4B0" 
              name="Reviews"
              shape={<RoundedBar />}
            />
            <Bar 
              dataKey="referrals" 
              stackId="a" 
              fill="#DC3DF7" 
              name="Referrals"
              shape={<RoundedBar />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
    <div className="w-full space-y-6 mt-10">
      <div className="w-full p-6 rounded-lg bg-[#0D182E]">
        <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Brand Experience</h2>
        <div className="h-px w-full bg-slate-700 mb-4" />
        
        <div className="h-[500px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={progressData}
              margin={{ top: 10, right: 30, left: 30, bottom: 100 }}
              fontFamily={"GeneralSansRegular"}
            >
              <defs>
                <linearGradient id="colorProgress" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#65D4B0" stopOpacity={0.8}/>
                  <stop offset="95.55%" stopColor="#65D4B0" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <CartesianGrid 
                horizontal={true}
                vertical={false}
                stroke="#1E2A44"
              />
              <XAxis
                dataKey="lable"
                stroke="#4B5563"
                tick={{ fill: '#9CA3AF', angle: -45, textAnchor: 'end' }}
                height={100}
                interval={0}
              />
              <YAxis
                type="number"
                domain={[0, 100]}
                stroke="#4B5563"
                tick={{ fill: '#9CA3AF' }}
              />
              <Tooltip
                contentStyle={{ 
                  background: '#1E2A44', 
                  border: 'none', 
                  borderRadius: '4px',
                  color: '#9CA3AF'
                }}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke="#65D4B0"
                fill="url(#colorProgress)"
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
    <div className="w-full space-y-6 mt-10">
      <div className="w-full p-6 rounded-lg bg-[#0D182E]">
        <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Age Groups</h2>
        <div className="h-px w-full bg-slate-700 mb-4" />
        
        <div className="h-[500px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={ageData}
              margin={{ top: 10, right: 30, left: 30, bottom: 100 }}
              fontFamily={"GeneralSansRegular"}
            >
              <defs>
                <linearGradient id="colorProgress" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#65D4B0" stopOpacity={0.8}/>
                  <stop offset="95.55%" stopColor="#65D4B0" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <CartesianGrid 
                horizontal={true}
                vertical={false}
                stroke="#1E2A44"
              />
              <XAxis
                dataKey="label"
                stroke="#4B5563"
                tick={{ fill: '#9CA3AF', angle: -45, textAnchor: 'end' }}
                height={100}
                interval={0}
              />
              <YAxis
                type="number"
                domain={[0, 100]}
                stroke="#4B5563"
                tick={{ fill: '#9CA3AF' }}
              />
              <Tooltip
                contentStyle={{ 
                  background: '#1E2A44', 
                  border: 'none', 
                  borderRadius: '4px',
                  color: '#9CA3AF'
                }}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke="#65D4B0"
                fill="url(#colorProgress)"
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>

          {/* First Donut Chart */}
          <div className="w-full p-6 rounded-lg bg-[#0D182E]">
            <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Finances</h2>
            <div className="h-px w-full bg-slate-700 mb-4" />
            
            <div className="h-[400px] relative">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={donutDatanew}
                    innerRadius="75%"
                    outerRadius="90%"
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {donutDatanew.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [`${value}%`, '']}
                    contentStyle={{ 
                      background: '#1E2A44', 
                      border: 'none', 
                      borderRadius: '4px',
                      fontFamily: 'GeneralSansRegular'
                    }}
                    itemStyle={{ color: '#65D4B0' }}
                  />
                  <Legend 
                    verticalAlign="bottom"
                    formatter={(value, entry) => (
                      <span className="text-slate-300 font-['GeneralSansRegular']">{value}</span>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <div className=" text-lg text-slate-400 font-['GeneralSansRegular']">Revenue</div>
                <div className="text-xl font-['GeneralSansBold'] text-slate-100">$10000</div>
                <div className="text-lg text-slate-400 font-['GeneralSansRegular']">Cash Back Referrals</div>
                <div className="text-xl font-['GeneralSansBold'] text-slate-100">$1000</div>
                <div className="text-lg text-slate-400 font-['GeneralSansRegular']">Cash Back Reviews</div>
                <div className="text-xl font-['GeneralSansBold'] text-slate-100">100</div>
                <div className="text-lg text-slate-400 font-['GeneralSansRegular']">Relationship Revenue</div>
                <div className="text-xl font-['GeneralSansBold'] text-slate-100">32</div>
              </div>
            </div>
          </div>
          
          {/* Stats Grid */}
         
      </div>
         {/* Business Analytics Container */}
      <div className="max-w-full bg-[#0D182E] rounded-lg shadow-md p-6 sm:p-8 mt-10">
        <h3 className="text-xl font-['VerminViva'] text-white mb-2">
          Top Town/Cities
        </h3>
        <div className="flex flex-col items-center">
          {/* Centered Image */}
          <div className="mb-8 w-full max-w-md">
            <img 
              src={secimage} 
              alt="Business Analytics" 
              className="w-full h-auto"
            />
          </div>

          {/* Progress Bars Container */}
          <div className="w-full space-y-6">
            {/* Progress Bar 1 */}
            <div>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-['GeneralSansMedium'] text-white mb-2">
                  Melbourne
                </h3>
                <span className="text-white text-base">75%</span>
              </div>
              <div className="w-full bg-white rounded-full h-2 dark:bg-white">
                <div 
                  className="h-2 rounded-full" 
                  style={{ 
                    width: '75%', 
                    backgroundColor: '#DC3DF7' 
                  }}
                ></div>
              </div>
            </div>

            {/* Progress Bar 2 */}
            <div>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-['GeneralSansMedium'] text-white mb-2">
                  Sydney
                </h3>
                <span className="text-white text-base">65%</span>
              </div>
              <div className="w-full bg-white rounded-full h-2 dark:bg-white">
                <div 
                  className="h-2 rounded-full" 
                  style={{ 
                    width: '65%', 
                    backgroundColor: '#DC3DF7' 
                  }}
                ></div>
              </div>
            </div>

            {/* Progress Bar 3 */}
            <div>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-['GeneralSansMedium'] text-white mb-2">
                  Brisbane
                </h3>
                <span className="text-white text-base">85%</span>
              </div>
              <div className="w-full bg-white rounded-full h-2 dark:bg-white">
                <div 
                  className="h-2 rounded-full" 
                  style={{ 
                    width: '85%', 
                    backgroundColor: '#DC3DF7' 
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-6 rounded-lg bg-[#0D182E] mt-10">
        <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Audience</h2>
        <div className="h-px w-full bg-slate-700 mb-4" />
        <div className="flex flex-col sm:flex-row justify-center mb-6 space-y-4 sm:space-y-0 sm:space-x-8">
          <div className="flex flex-col items-center w-full sm:w-1/4">
            <img 
              src={maleImage} 
              alt="Male" 
              className="w-24 h-24 object-contain"
            />
            <span className="text-white font-['GeneralSansMedium'] mt-2">Male  320</span>
          </div>
          <div className="flex flex-col items-center w-full sm:w-1/4">
            <img 
              src={femaleImage} 
              alt="Female"
              className="w-24 h-24 object-contain"
            />
            <span className="text-white font-['GeneralSansMedium'] mt-2">Female  215</span>
          </div>
        </div>
        
        <div className="h-[400px] relative">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={donutData}
                innerRadius="60%"
                outerRadius="80%"
                paddingAngle={0}
                dataKey="value"
              >
                {donutData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip 
                formatter={(value) => [`${value}%`, '']}
                contentStyle={{ 
                  background: '#1E2A44', 
                  border: 'none', 
                  borderRadius: '4px',
                  fontFamily: 'GeneralSansRegular'
                }}
                itemStyle={{ color: '#65D4B0' }}
              />
              <Legend 
                verticalAlign="bottom"
                formatter={(value, entry) => (
                  <span className="text-slate-300 font-['GeneralSansRegular']">{value}</span>
                )}
              />
            </PieChart>
          </ResponsiveContainer>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Male</div>
          <div className="text-3xl font-['GeneralSansBold'] text-slate-100">100</div>
          <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Female</div>
          <div className="text-3xl font-['GeneralSansBold'] text-slate-100">70</div>
            <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Essence of Being</div>
            <div className="text-3xl font-['GeneralSansBold'] text-slate-100">50</div>
          </div>
        </div>
      </div>

      <div className="w-full bg-[#0D182E] py-12 px-8 rounded-2xl mt-10">
      <div className="max-w-5xl mx-auto flex flex-col lg:flex-row">
        {/* Chart Container */}
        <div className="w-full lg:w-2/3 pr-0 lg:pr-8">
          <h2 className="font-['VerminViva'] text-white text-3xl mb-6 text-center lg:text-left">
            {selectedEmployee.name}'s  Reviews Breakdown
          </h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={selectedEmployee.stats}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid
                horizontal={true}
                vertical={false}
                stroke="rgba(255,255,255,0.1)"
              />
              <XAxis
                dataKey="metric"
                tick={{ fill: "white" }}
                axisLine={{ stroke: "white" }}
              />
              <YAxis
                tick={{ fill: "white" }}
                axisLine={{ stroke: "white" }}
                domain={[0, 100]}
               
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#0D182E",
                  color: "white",
                  border: "1px solid white",
            
                }}
                formatter={(value) => [`${value}%`, "Score"]}
              />
              <Bar
                dataKey="value"
                fill="#65D4B0"
                barSize={35}
                name="Score"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Employee Selection */}
        <div className="w-full lg:w-1/3 flex flex-col space-y-4 mt-8 lg:mt-0">
          {employees.map((employee) => (
            <button
              key={employee.id}
              onClick={() => setSelectedEmployee(employee)}
              className={`w-full py-3 px-4 text-[#0D182E] rounded-lg transition-all duration-300 flex items-center space-x-4 ${
                selectedEmployee.id === employee.id
                  ? "bg-[#B7BDCA]"
                  : "bg-[#0D182E] text-[white] hover:bg-[#1A2742]"
              }`}
            >
              <img
                src={employee.image}
                alt={employee.name}
                className="w-14 h-14 rounded-md"
              />
              <span className="text-lg">{employee.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
      <div className="w-full p-6 rounded-lg bg-[#0D182E] mt-10 relative">
      <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Customer Review Vs Customer Experience</h2>
      <div className="h-px w-full bg-slate-700 mb-4" />
      
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/4 h-[400px] relative mb-6 lg:mb-0">
          {/* Logo near dial origin */}
          <div className="absolute top-1/2 left-1/2 pr-14 pb-4 transform -translate-x-1/2 -translate-y-1/2 z-10">
            <img
              src={logo}
              alt="Company Logo"
              className="w-10 h-10 object-contain"
            />
          </div>

          <ResponsiveContainer width="100%" height="100%">
            <RadialBarChart 
              innerRadius="70%" 
              outerRadius="90%" 
              data={[currentPerformance]} 
              startAngle={180} 
              endAngle={0}
            >
              {/* Customer Experience Dial */}
              <RadialBar 
                minAngle={15} 
                background 
                clockWise 
                dataKey="value" 
                cornerRadius={14}
                fill="#DC3DF7"
                outerRadius="40%"
              />
              
              {/* Reviews Dial */}
              <RadialBar 
                minAngle={15} 
                background 
                clockWise 
                dataKey="value" 
                cornerRadius={14}
                fill="#3DE9DC"
              />
            </RadialBarChart>
          </ResponsiveContainer>
          
          {/* Value Labels inside radial bars */}
          <div className="absolute top-1/2 left-1/4 sm:pt-5 sm:pl-28 pl-20 pt-5 text-center">
            <div className=" text-xs font-['GeneralSansBold'] text-[#DC3DF7]">Brand Experience <span className="text-white text-sm">{currentPerformance.value}</span> </div>
          </div>
          
          <div className="absolute top-1/2 right-1/3 pr-14 pt-5 text-center">
            <div className="text-xs font-['GeneralSansBold'] text-[#3DE9DC]">Total Reviews <span className="text-white text-sm">{currentPerformance.value} </span></div>
          </div>

        
          
          {/* Small logos on outer radial */}

        

          {/* Custom Needle */}
          <div 
  className="absolute top-36 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1 h-28 bg-white origin-bottom"
  style={{
    transform: `translate(-50%, -50%) rotate(${270 + (currentPerformance.value * 180 / 100)}deg)`,
    boxShadow: '0 0 10px rgba(255,255,255,0.3)',
    transition: 'transform 1.5s ease-out' // Add smooth transition
  }}
>
  <div 
    className="absolute bottom-0 left-1/2 -translate-x-1/2 w-4 h-4 bg-white border-2 border-[#DC3DF7] rounded-full"
    style={{
      transition: 'all 0.5s ease-out' // Add smooth transition to the circle as well
    }}
  />
</div>
        </div>
        
        {/* Performance Tabs */}
        <div className="w-full lg:w-1/4 lg:pl-4 flex flex-col space-y-2">
          {performanceData.map((perf) => (
            <button
              key={perf.name}
              onClick={() => setCurrentPerformance(perf)}
              className={`w-full p-3 text-left rounded-lg transition-all duration-300 flex justify-between items-center shadow-md 
                ${currentPerformance.name === perf.name 
                  ? 'bg-gradient-to-r from-[#3DE9DC] to-[#3DE9DC]/80 text-[white] font-bold' 
                  : 'bg-[#1E2A44] text-slate-300 hover:bg-[#2C3E5A] hover:shadow-xl'
              }`}
            >
              <span className="text-base font-['GeneralSansMedium']">{perf.name}</span>
              <span className="text-sm font-['GeneralSansBold'] bg-[#0D182E]/50 px-2 py-1 rounded-full">{perf.reviews}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default BusinessAnalytics;