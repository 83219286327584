import React, { useState } from 'react';
import { Upload, ArrowLeft, ArrowRight, Camera, Image as ImageIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const MediaUploadField = ({ 
  icon: Icon, 
  title, 
  subtitle, 
  onDragOver, 
  onDragLeave, 
  onDrop, 
  onClick, 
  isDragging 
}) => (
  <div 
    className={`relative mb-6 ${isDragging ? 'border-[#959DB0]' : 'border-[#959DB0]'}`}
  >
    <label className="absolute left-4 -top-2 text-xs text-[#B7BDCA] bg-[#0D182E] px-1 z-10 font-medium">
      {title}
    </label>
    <div
      className="w-full border border-[#959DB0] rounded-lg bg-[#0D182E] p-8 flex flex-col items-center justify-center cursor-pointer"
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={onClick}
    >
      <div className="bg-[#4D6F8F] rounded-full w-16 h-16 flex items-center justify-center mb-4">
        <Icon className="h-8 w-8 text-white" />
      </div>
      <p className="text-white font-['GeneralSansMedium'] mb-1">{title}</p>
      <p className="text-[#959DB0] text-sm">{subtitle}</p>
    </div>
  </div>
);

const BusinessMediaUploadStep = () => {
  const [formData, setFormData] = useState({
    profileMedia: null,
    businessLogo: null
  });
  const navigate = useNavigate();
  
  const [isDraggingProfile, setIsDraggingProfile] = useState(false);
  const [isDraggingLogo, setIsDraggingLogo] = useState(false);

  const handleProfileMediaDragOver = (e) => {
    e.preventDefault();
    setIsDraggingProfile(true);
  };

  const handleProfileMediaDragLeave = () => {
    setIsDraggingProfile(false);
  };

  const handleProfileMediaDrop = (e) => {
    e.preventDefault();
    setIsDraggingProfile(false);
    handleProfileMediaUpload(e.dataTransfer.files);
  };

  const handleLogoMediaDragOver = (e) => {
    e.preventDefault();
    setIsDraggingLogo(true);
  };

  const handleLogoMediaDragLeave = () => {
    setIsDraggingLogo(false);
  };

  const handleLogoMediaDrop = (e) => {
    e.preventDefault();
    setIsDraggingLogo(false);
    handleLogoUpload(e.dataTransfer.files);
  };

  const handleProfileMediaUpload = (files) => {
    const file = files[0];
    if (file && (file.type.startsWith('image/') || file.type.startsWith('video/'))) {
      setFormData(prevState => ({
        ...prevState,
        profileMedia: file
      }));
    }
  };

  const handleLogoUpload = (files) => {
    const file = files[0];
    if (file && (file.type.startsWith('image/') || file.type === 'image/gif')) {
      setFormData(prevState => ({
        ...prevState,
        businessLogo: file
      }));
    }
  };

  const removeProfileMedia = () => {
    setFormData(prevState => ({
      ...prevState,
      profileMedia: null
    }));
  };

  const removeBusinessLogo = () => {
    setFormData(prevState => ({
      ...prevState,
      businessLogo: null
    }));
  };

  return (
    <div className="bg-[#4D6F8F] min-h-screen pb-[102px] flex flex-col">
      <div className="px-4 pt-20 pb-8">
        <div className="flex justify-center items-center">
          <img src={logo} width={120} alt="Review Pay Logo" />
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-2xl p-4 mx-4">
        {/* Profile Media Upload */}
        <input
          id="profileMediaInput"
          type="file"
          className="hidden"
          accept="image/*,video/*"
          onChange={(e) => handleProfileMediaUpload(e.target.files)}
        />
        <MediaUploadField
          icon={Camera}
          title="Upload Profile Picture or Video"
          subtitle="Click to upload or drag and drop (Image or Video)"
          onDragOver={handleProfileMediaDragOver}
          onDragLeave={handleProfileMediaDragLeave}
          onDrop={handleProfileMediaDrop}
          onClick={() => document.getElementById('profileMediaInput').click()}
          isDragging={isDraggingProfile}
        />

        {/* Profile Media Preview */}
        {formData.profileMedia && (
          <div className="mb-6">
            <div className="relative inline-block">
              {formData.profileMedia.type.startsWith('image/') ? (
                <img 
                  src={URL.createObjectURL(formData.profileMedia)} 
                  alt="Profile Media" 
                  className="w-32 h-32 object-cover rounded-lg"
                />
              ) : (
                <video 
                  src={URL.createObjectURL(formData.profileMedia)} 
                  className="w-32 h-32 object-cover rounded-lg"
                  controls
                />
              )}
              <button
                type="button"
                onClick={removeProfileMedia}
                className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 
                           flex items-center justify-center text-xs hover:bg-red-600"
              >
                ×
              </button>
            </div>
          </div>
        )}

        {/* Business Logo Upload */}
        <input
          id="logoMediaInput"
          type="file"
          className="hidden"
          accept="image/*,image/gif"
          onChange={(e) => handleLogoUpload(e.target.files)}
        />
        <MediaUploadField
          icon={ImageIcon}
          title="Upload Business Logo"
          subtitle="Click to upload or drag and drop (Image or GIF)"
          onDragOver={handleLogoMediaDragOver}
          onDragLeave={handleLogoMediaDragLeave}
          onDrop={handleLogoMediaDrop}
          onClick={() => document.getElementById('logoMediaInput').click()}
          isDragging={isDraggingLogo}
        />

        {/* Business Logo Preview */}
        {formData.businessLogo && (
          <div className="mb-6">
            <div className="relative inline-block">
              <img 
                src={URL.createObjectURL(formData.businessLogo)} 
                alt="Business Logo" 
                className="w-32 h-32 object-cover rounded-lg"
              />
              <button
                type="button"
                onClick={removeBusinessLogo}
                className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 
                           flex items-center justify-center text-xs hover:bg-red-600"
              >
                ×
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Navigation Buttons */}
      <button 
        onClick={() => navigate('/BusinessVerification')}
        className="bg-[#0D182E] py-5 rounded-xl w-[98%] mx-auto mt-8 flex items-center justify-center"
      >
        <span className="text-white text-lg font-bold font-['GeneralSansMedium']">Submit</span>
      </button>


    </div>
  );
};

export default BusinessMediaUploadStep;