import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!uid && !token) {
      navigate('/');
    }
  }, [uid, token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      // Constructing the API URL with uid and token in the path
      const response = await fetch(`https://superadmin.reviewpay.com.au/reviewpayrole_api/password-reset/${uid}/${token}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: newPassword
        })
      });

      if (response.ok) {
        // Password successfully reset
        navigate('/SignIn');
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to reset password. Please try again.');
      }
    } catch (err) {
      setError('Network error. Please check your connection and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>
        </div>
      </div>

      {/* New Password Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-md border border-white/20 shadow-2xl">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Create New Password</h2>
        
        <p className="text-white/70 text-center font-['GeneralSansMedium'] mb-6">
          Your new password must be different from previously used passwords.
        </p>

        {error && (
          <div className="text-pink-500 px-4 py-2 rounded-lg text-sm mb-4 font-[GeneralSansRegular] text-center">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4 -mb-5">
          <div>
            <label className="text-white/70 font-['GeneralSansMedium'] text-sm">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="w-full mt-1 px-3 py-3 text-white bg-[#667d9c] border border-white/10 rounded-lg 
              placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30 
              focus:border-transparent backdrop-blur-sm transition-all duration-300 placeholder:font-[GeneralSansRegular]"
              placeholder="Enter new password"
            />
          </div>
          <div>
            <label className="text-white/70 font-['GeneralSansMedium'] text-sm">Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full mt-1 px-3 py-3 text-white bg-[#667d9c] border border-white/10 rounded-lg 
              placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30 
              focus:border-transparent backdrop-blur-sm transition-all duration-300 placeholder:font-[GeneralSansRegular]"
              placeholder="Confirm your new password"
            />
          </div>
          
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-[#0D182E] text-white 
            py-3 px-4 rounded-lg font-['GeneralSansMedium'] hover:opacity-90 
            transition-all duration-300 shadow-lg hover:shadow-xl 
            hover:scale-[1.02] disabled:opacity-70 disabled:hover:scale-100"
          >
            {isLoading ? 'Saving...' : 'Save Changes'}
          </button>
        </form>

        <div className="relative mt-10 mb-6">
          <span className="block w-full h-px bg-white/30"></span>
        </div>

        <div className="mt-2 text-center">
          <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
            Already have an account?
          </span>
          <button 
            onClick={() => navigate('/SignIn')}
            className="text-white font-['GeneralSansMedium'] hover:underline"
          >
            Log in
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordPage;