import React, { useState, useEffect } from 'react';
import { 
  Home, 
  User, 
  Share2, 
  Wallet, 
  FileText, 
  Settings, 
  HelpCircle, 
  Menu, 
  Search, 
  X,
  Users,
  Send,
  Bell,
  PlusCircle,
  BookOpen,
  Link2,
  Briefcase,
  MessageCircle,
  Shield,
  BookOpenText,
  Scale,
  HandHeartIcon,
  BarChart,
  FormInputIcon,
 QrCode, 
 File,
 ScanQrCode,
 ScanLine,
 ReceiptText,
 LucideQrCode,
 MessageSquareReplyIcon
} from 'lucide-react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from "./assets/images/loogfinal.png";
import ProfileImage from './assets/images/crown.png';
const DUMMY_PRODUCTS = [
  {
    id: 1,
    name: "Organic Apple Juice",
    brand: "Nature's Best",
    category: "Beverages",
    price: 4.99,
    description: "100% organic pressed apple juice"
  },
  {
    id: 2,
    name: "Whole Wheat Bread",
    brand: "Bakery Fresh",
    category: "Bakery",
    price: 3.49,
    description: "Freshly baked whole wheat bread"
  },
  {
    id: 3,
    name: "Greek Yogurt",
    brand: "Dairy Delight",
    category: "Dairy",
    price: 2.99,
    description: "Creamy Greek yogurt, high in protein"
  },
  {
    id: 4,
    name: "Organic Spinach",
    brand: "Green Farms",
    category: "Produce",
    price: 3.99,
    description: "Fresh organic spinach leaves"
  }
];


const Dashboard = () => {
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSideSliderOpen, setIsSideSliderOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [isScanDropdownOpen, setIsScanDropdownOpen] = useState(false);
  const [isMobileScanDropdownOpen, setIsMobileScanDropdownOpen] = useState(false);


  // ... existing methods
  const handleScanQR = () => {
    // Placeholder for QR code scan functionality
    alert('Scanning QR code');
  };

  const handleScanInvoice = () => {
    // Placeholder for invoice scan functionality
    alert('Scanning invoice');
  };

  // Scan Dropdown Component for Desktop
  const ScanDropdown = () => (
    <div className="absolute top-full -right-10 mt-2 w-48 bg-[#0D182E] text-white shadow-lg rounded-lg z-50">
      <div 
        className="px-4 py-2 ml-4 mr-4 cursor-pointer group flex items-center  hover:bg-gradient-to-r hover:from-[#3DE9DC] hover:via-[#889EE7] hover:to-[#DC3DF7] transition-colors duration-200 hover:rounded-lg"
        onClick={handleScanProduct}
      >
        <ScanLine size={20} className="mr-2" /> Scan Product
      </div>
      <div 
        className="px-4 py-2 ml-4 mr-4 cursor-pointer group flex items-center  hover:bg-gradient-to-r hover:from-[#3DE9DC] hover:via-[#889EE7] hover:to-[#DC3DF7] transition-colors duration-200 hover:rounded-lg"
        onClick={handleScanQR}
      >
        <QrCode size={20} className="mr-2" /> Scan QR
      </div>
      <div 
        className="px-4 py-2 ml-4 mr-4 mb-2 cursor-pointer group flex items-center  hover:bg-gradient-to-r hover:from-[#3DE9DC] hover:via-[#889EE7] hover:to-[#DC3DF7] transition-colors duration-200 hover:rounded-lg"
        onClick={handleScanInvoice}
      >
        <ReceiptText size={20} className="mr-2" /> Scan Invoice
      </div>
    </div>
  );


  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const results = DUMMY_PRODUCTS.filter(product => 
      product.name.toLowerCase().includes(query.toLowerCase()) ||
      product.brand.toLowerCase().includes(query.toLowerCase()) ||
      product.category.toLowerCase().includes(query.toLowerCase())
    );

    setSearchResults(results);
  };

  const handleScanProduct = (productId) => {

    alert(`Scanning product with ID: ${productId}`);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll Event Listener
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsNavVisible(currentScrollY <= lastScrollY || currentScrollY <= 100);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  // Side Slider Menu Item Component
  const SideSliderMenuItem = ({ icon: Icon, text, onClick }) => (
    <div 
      className="flex items-center cursor-pointer group space-x-3 p-3 hover:bg-gradient-to-r hover:from-[#3DE9DC] hover:via-[#889EE7] hover:to-[#DC3DF7] transition-colors duration-200 hover:rounded-lg"
      onClick={onClick}
    >
      <Icon 
        size={24} 
        className="text-[white] group-hover:text-[white]" 
      />
      <span className="text-base font-['GeneralSansMedium'] text-[white] group-hover:text-[white]">
        {text}
      </span>
    </div>
  );
  const NavItem = ({ icon: Icon, text, onClick }) => (
    <div 
      className="flex items-center cursor-pointer group space-x-1 p-2 hover:bg-gradient-to-r hover:from-[#3DE9DC] hover:via-[#889EE7] hover:to-[#DC3DF7] hover:text-white rounded-lg transition-colors duration-200"
      onClick={onClick}
    >
      <Icon 
        size={20} 
        className="text-[white] group-hover:text-white" 
      />
      <span className="text-sm font-['GeneralSansMedium'] text-[white] group-hover:text-white">
        {text}
      </span>
    </div>
  );


  const handleHomeNavigation = () => {
    navigate('/BusinessDashboard');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };

  const handleTermsandConditions = () => {
    navigate('TermsandConditions');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleLegalPolicy = () => {
    navigate('LegalPolicy');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };

  const handleIntegrations = () => {
    navigate('Integrations');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleCustomers = () => {
    navigate('Customer');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleSettings = () => {
    navigate('settings');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlenotifications = () => {
    navigate('notifications');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleProducts = () => {
    navigate('AddProducts');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlewallet = () => {
    navigate('wallet');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlePrivacyPolicy = () => {
    navigate('PrivacyPolicy');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlehelp = () => 
  {
    navigate('help');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleourproducts =()=>
  {
    navigate('BusinessDetail/products');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }

  const handleTeam =()=>
  {
    navigate('BusinessDetail/team');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }
  const handlebusinessimage =()=>{

   navigate('BusinessDetail');
   setIsMobileMenuOpen(false);
   setIsSideSliderOpen(false);

  }
  const handleanalytics =()=>{

    navigate("BusinessAnalytics");
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }

  const handleCreateFeedback =()=>{
    navigate("CreateFormBusiness");
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }
  
  const handleInvoice =()=>{

    navigate("InvoiceHistory");
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);

  }
  const handlefaqs =()=>{

    navigate("faqs");
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);

  }
  const handleqrcode = () =>{

    navigate("QRCode");
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }

  const handleSendLink =()=>{
    navigate("SendLink");
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }
  
 const handleMyFeedback =()=>{
  navigate("MyFeedback");
  setIsMobileMenuOpen(false);
  setIsSideSliderOpen(false);
 }

  return (
    <div className="min-h-screen bg-[#4D6F8F] relative overflow-y-auto ">
      {!isMobile && isSideSliderOpen && (
        <div className="fixed top-0 right-0 h-full w-64 bg-[#0D182E]  shadow-lg z-50 overflow-y-auto">
          <div className="p-4 border-b-4 border-[#DC3DF7] flex justify-between items-center">
            <h2 className="text-xl font-['VerminViva'] text-[white]">Business profile</h2>
            <button onClick={() => setIsSideSliderOpen(false)}>
              <X size={24} className="text-[white]" />
            </button>
          </div>
          <div className="p-4 space-y-3 text-white" >
          <SideSliderMenuItem icon={Home} text="Home" onClick={handleHomeNavigation} />
            <SideSliderMenuItem icon={BarChart} text="Business Analytics" onClick={handleanalytics} />
            <SideSliderMenuItem icon={FormInputIcon} text="Create Feedback" onClick={handleCreateFeedback} />
            <SideSliderMenuItem icon={Wallet} text="Wallet" onClick={handlewallet}/>
            <SideSliderMenuItem icon={FileText} text="Templates" />
            <SideSliderMenuItem icon={Link2} text="Integrations" onClick={handleIntegrations}/>
            <SideSliderMenuItem icon={Briefcase} text="Our Team" onClick={handleTeam}/>
            <SideSliderMenuItem icon={BookOpen} text="Our Products" onClick={handleourproducts} />
            <SideSliderMenuItem icon={Settings} text="Settings" onClick={handleSettings} />
            <SideSliderMenuItem icon={HelpCircle} text="Help and Support" onClick={handlehelp}/>
            <SideSliderMenuItem icon={BookOpen} text="About/FAQ" onClick={handlefaqs} />
            <SideSliderMenuItem icon={Shield} text="Privacy Policy" onClick={handlePrivacyPolicy} />
            <SideSliderMenuItem icon={Scale} text="Terms and Conditions" onClick={handleTermsandConditions}  />
            <SideSliderMenuItem icon={BookOpenText} text="Legal Policy" onClick={handleLegalPolicy} />
            <SideSliderMenuItem icon={File} text="Invoice History" onClick={handleInvoice} />    
            <SideSliderMenuItem icon={MessageSquareReplyIcon} text="My Feedback" onClick={handleMyFeedback} />    
          </div>
        </div>
      )}

      {/* Mobile Menu Overlay */}
      {isMobile && isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-hidden">
          <div className="fixed right-0 top-0 h-full w-64 bg-[#0D182E] p-4 overflow-y-auto">
          <div className="p-4 border-b-4 border-[#DC3DF7] flex justify-between items-center">
          <h2 className="text-xl font-['VerminViva'] text-[white]">Business profile</h2>
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="absolute top-4 right-4"
            >
              <X size={24} className="text-white" />
            </button>
            </div>
            <div className="mt-4 space-y-4 pb-20">
            <NavItem icon={Home} text="Home" onClick={handleHomeNavigation} />
              <NavItem icon={BarChart} text="Business Analytics" onClick={handleanalytics} />
              <NavItem icon={FormInputIcon} text="Create Feedback" onClick={handleCreateFeedback} />
              <NavItem icon={Wallet} text="Wallet" onClick={handlewallet}/>
              <NavItem icon={FileText} text="Templates" />
              <NavItem icon={Link2} text="Integrations" onClick={handleIntegrations} />
              <NavItem icon={Briefcase} text="Our Team" onClick={handleTeam} />
              <NavItem icon={BookOpen} text="Our Products" onClick={handleourproducts} />
              <NavItem icon={Settings} text="Settings" onClick={handleSettings}/>
              <NavItem icon={HelpCircle} text="Help and Support" onClick={handlehelp} />
              <NavItem icon={BookOpen} text="About/FAQ" onClick={handlefaqs} />
              <NavItem icon={Shield} text="Privacy Policy" onClick={handlePrivacyPolicy}/>
              <NavItem icon={Scale} text="Terms and Conditions" onClick={handleTermsandConditions} />
              <NavItem icon={BookOpenText} text="Legal Policy" onClick={handleLegalPolicy} />
              <NavItem icon={File} text="Invoice History" onClick={handleInvoice} />
              <NavItem icon={MessageSquareReplyIcon} text="My Feedback" onClick={handleMyFeedback} />    
              <div className="group">
          <NavItem 
            icon={ScanLine} 
            text="Scan" 
            onClick={() => setIsMobileScanDropdownOpen(!isMobileScanDropdownOpen)}
          />
          {isMobileScanDropdownOpen && (
            <div className="pl-8 space-y-2 mt-2">
              <NavItem 
                icon={ScanLine} 
                text="Scan Product" 
                onClick={handleScanProduct} 
              />
              <NavItem 
                icon={QrCode} 
                text="Scan QR" 
                onClick={handleScanQR} 
              />
              <NavItem 
                icon={ReceiptText} 
                text="Scan Invoice" 
                onClick={handleScanInvoice} 
              />
            </div>
          )}
        </div>
            </div>
          </div>
        </div>
      )}

      {/* Navigation Bar */}
      <nav 
        className={`fixed top-0 w-full bg-[#0D182E] border-b-4 border-[#0D182E] z-40 transition-transform duration-300 
        ${isNavVisible ? 'translate-y-0' : '-translate-y-full  shadow-[0_0_20px_rgba(61,233,220,0),0_0_40px_rgba(136,158,231,0),0_0_60px_rgba(220,61,247,0)]'}
        shadow-[0_0_20px_rgba(61,233,220,0.8),0_0_40px_rgba(136,158,231,0.8),0_0_60px_rgba(220,61,247,0.8)]`}
        style={{
          borderImage: 'linear-gradient(105.41deg, #3DE9DC -17.48%, #889EE7 35.37%, #DC3DF7 99.28%)',
          borderImageSlice: 1,
        }}
      >
        <div className="max-w-8xl mx-2 sm:ml-10 sm:mr-2 pl-2">
          <div className="flex items-center justify-between h-16">
            {/* Logo and Company Name */}
            <div className="flex items-center -ml-4 lg:ml-9">
              <img src={logo} alt="Company Logo" className="h-8 sm:h-10" />
            </div>


<div className=" md:flex relative items-center sm:w-96 w-48 ml-1">
  <input
    type="text"
    placeholder="Search..."
    value={searchQuery}
    onChange={handleSearch}
    className="w-full pl-10 pr-4 py-2 rounded-lg bg-white/90 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#DC3DF7] focus:border-transparent font-['GeneralSansRegular'] text-[#0D182E]"
  />
  <Search size={20} className="absolute bottom-3 left-3 sm:left-3 text-gray-500" />
  {searchQuery && (
    <div className="absolute top-full left-0 right-0 mt-2 max-w-xl bg-white rounded-lg shadow-lg z-50">
      {searchResults.length > 0 ? (
        searchResults.map(product => (
          <div 
            key={product.id} 
            className="bg-white rounded-lg p-4 mb-2 border-b last:border-b-0 border-gray-200 flex justify-between items-center"
          >
            <div>
              <h3 className="font-bold text-[#0D182E]">{product.name}</h3>
              <p className="text-gray-700">{product.brand} - {product.category}</p>
              <p className="text-gray-700">${product.price}</p>
              <p className="text-sm text-gray-600 mt-2">{product.description}</p>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-700 p-4">No products found</p>
      )}
    </div>
      )}
      </div>

            {/* Desktop Navigation Items */}
            <div className="hidden md:flex items-center space-x-5">
          <NavItem icon={Home} text="Home" onClick={handleHomeNavigation} />
          <NavItem icon={Users} text="Customers" onClick={handleCustomers} />
          <NavItem icon={Send} text="Send Review" onClick ={handleSendLink} />
          <NavItem icon={Bell} text="Notifications" onClick={handlenotifications} />
          <NavItem icon={PlusCircle} text="Add Products" onClick={handleProducts}/>
          <NavItem icon={LucideQrCode} text="Qr Code" onClick={handleqrcode}/>
          
          {/* Scan Dropdown for Desktop */}
          <div className="relative">
            <div 
              className="flex items-center cursor-pointer group space-x-1 p-2 hover:bg-gradient-to-r hover:from-[#3DE9DC] hover:via-[#889EE7] hover:to-[#DC3DF7] hover:text-white rounded-lg transition-colors duration-200"
              onClick={() => setIsScanDropdownOpen(!isScanDropdownOpen)}
            >
              <ScanLine 
                size={20} 
                className="text-[white] group-hover:text-white" 
              />
              <span className="text-sm font-['GeneralSansMedium'] text-[white] group-hover:text-white">
                Scan
              </span>
            </div>
            {isScanDropdownOpen && <ScanDropdown />}
          </div>
        </div>


             {/* Mobile and Desktop Menu Buttons */}
             <div className="flex items-center space-x-4 sm:mr-10">
            {isMobile ? (
              <>
                <button onClick={() => setIsMobileMenuOpen(true)} className="order-1">
                  <NavItem icon={Menu} text="Menu" />
                </button>
                <div className="relative order-2" onClick={handlebusinessimage}>
                  <img 
                    src={ProfileImage} 
                    alt="Profile" 
                    className="relative z-10 w-10 h-10  object-contain" 
                  />
                </div>
              </>
            ) : (
              <>
                <button onClick={() => setIsSideSliderOpen(!isSideSliderOpen)}>
                  <NavItem icon={Menu} text="Menu" />
                </button>
                <div className="relative" onClick={handlebusinessimage}>
                  
                  <img 
                    src={ProfileImage} 
                    alt="Profile" 
                    className="relative z-10 w-10 h-10  object-contain" 
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>


      {/* Main Content Area */}
      <Outlet />
    </div>
  );
};

export default Dashboard;