import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';
import FacebookIcon from './assets/images/facebook.png';
import AppleIcon from './assets/images/apple.png';

const SignUpPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }
    try {
      const formDataToSend = new FormData();
      
      // Append the nested structure for createAccount
      formDataToSend.append('email', formData.email);
      formDataToSend.append('username', formData.username);
      formDataToSend.append('password', formData.password);
      formDataToSend.append('confirmPassword', formData.confirmPassword);
      formDataToSend.append('role', 'business');
      const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/signup/', {
        method: 'POST',
        body: formDataToSend
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Signup failed');
      }

      // Handle successful signup
      navigate('/signin');
    } catch (err) {
      setError(err.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold"
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize: 8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>
        </div>
      </div>

      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-md border border-white/20 shadow-2xl mb-10">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Create your account</h2>
        
        {error && (
          <div className="mb-4 p-3 bg-red-500/20 border border-red-500/50 rounded-lg text-white text-sm">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4 -mb-8">
          <div>
            <label className="text-white/70 font-['GeneralSansMedium'] text-sm">Email</label>
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleInputChange}
              className="w-full mt-1 px-3 py-3 text-white bg-[#667d9c] border border-white/10 rounded-lg 
              placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30 
              focus:border-transparent backdrop-blur-sm transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              placeholder="Enter your email"
            />
          </div>

          <div>
            <label className="text-white/70 font-['GeneralSansMedium'] text-sm">Username</label>
            <input
              type="text"
              name="username"
              required
              value={formData.username}
              onChange={handleInputChange}
              className="w-full mt-1 px-3 py-3 text-white bg-[#667d9c] border border-white/10 rounded-lg 
              placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30 
              focus:border-transparent backdrop-blur-sm transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              placeholder="Enter your username"
            />
          </div>

          <div>
            <label className="text-white/70 font-['GeneralSansMedium'] text-sm">Password</label>
            <input
              type="password"
              name="password"
              required
              value={formData.password}
              onChange={handleInputChange}
              className="w-full mt-1 px-3 py-3 text-white bg-[#667d9c] border border-white/10 rounded-lg 
              placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30 
              focus:border-transparent backdrop-blur-sm transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              placeholder="Enter your password"
            />
          </div>

          <div>
            <label className="text-white/70 font-['GeneralSansMedium'] text-sm">Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              required
              value={formData.confirmPassword}
              onChange={handleInputChange}
              className="w-full mt-1 px-3 py-3 text-white bg-[#667d9c] border border-white/10 rounded-lg 
              placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30 
              focus:border-transparent backdrop-blur-sm transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              placeholder="Confirm your password"
            />
          </div>

          <div>
            <label className="inline-flex items-center">
              <input 
                type="checkbox" 
                required
                className="rounded border-white/30 text-white bg-white/10 focus:ring-white/50 focus:ring-opacity-50"
              />
              <span className="ml-2 text-white/70 text-sm font-['GeneralSansRegular']">
                I agree to the{" "}
                <a href="#" className="text-white hover:underline">Terms of Service</a>
                {" "}and{" "}
                <a href="#" className="text-white hover:underline">Privacy Policy</a>
              </span>
            </label>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white 
                     py-3 px-4 rounded-lg font-['GeneralSansBold'] hover:opacity-90 
                     transition-all duration-300 shadow-lg hover:shadow-xl 
                     hover:scale-[1.02] disabled:opacity-70 disabled:hover:scale-100"
          >
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </button>
        </form>

        <div className="relative mt-10 mb-6">
          <span className="block w-full h-px bg-white/30"></span>
        </div>

        <div className="space-y-3">
          <button 
            className="w-full flex items-center justify-center gap-x-3 py-3 
            bg-white/5 border border-white/10 rounded-lg 
            hover:bg-white/10 transition-all duration-300"
          >
            <svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_17_40)">
                <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
                <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
                <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
                <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
              </g>
              <defs>
                <clipPath id="clip0_17_40">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-white font-['GeneralSansRegular']">Continue with Google</span>
          </button>

          <button 
            className="w-full flex items-center justify-center gap-x-3 py-3 
            bg-white/5 border border-white/10 rounded-lg 
            hover:bg-white/10 transition-all duration-300"
          >
            <img src={FacebookIcon} alt="Facebook" className="w-5 h-5" />
            <span className="text-white font-['GeneralSansRegular']">Continue with Facebook</span>
          </button>
          <button 
            className="w-full flex items-center justify-center gap-x-3 py-3 
            bg-white/5 border border-white/10 rounded-lg 
            hover:bg-white/10 transition-all duration-300"
          >
            <img src={AppleIcon} alt="Facebook" className="w-5 h-5" />
            <span className="text-white font-['GeneralSansRegular']">Continue with Apple</span>
          </button>
        </div>
        
        <div className="mt-2 text-center">
          <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
            Already have an account?
          </span>
          <button 
            onClick={() => navigate('/SignIn')}
            className="text-white font-['GeneralSansMedium'] hover:underline"
          >
            Log in
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;