import React, { useState, useEffect } from 'react';
import { Search, ArrowUpDown, Calendar, MoreVertical } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ProductsPage = () => {
  const progressData = [
    { label: 'Quality', value: 75 },
    { label: 'Performance', value: 60 },
    { label: 'Easy To Use', value: 90 },
    { label: 'Durability', value: 60 },
  ];

  // State management
  const [productData, setProductData] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate =useNavigate();

  // Fetch products from API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/get_products', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Replace with your token management solution
          }
        });
        if(response.status=='401'){
          localStorage.removeItem('accessToken');
          navigate('/signin')
        }
        
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        
        const data = await response.json();
        
        // Transform API data to match your table structure
        const transformedProducts = data.products.map(product => ({
          id: product.id,
          image: product.product_images[0] || '', 
          name: product.name,
          businessName: "Business", 
          price: parseFloat(product.price),
          reviewCount: 0, 
          rating: 0, 
          dateAdded: new Date().toISOString().split('T')[0], 
          description: product.description,
          stockStatus: "In Stock" 
        }));
        
        setProductData(transformedProducts);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Existing action handlers
  const handlePause = (id) => {
    console.log('Pause product:', id);
    setOpenDropdownId(null);
  };

  const handleDelete = (id) => {
    const updatedProducts = productData.filter(product => product.id !== id);
    setProductData(updatedProducts);
    setOpenDropdownId(null);
  };

  const handleEdit = (id) => {
    console.log('Edit product:', id);
    setOpenDropdownId(null);
  };

  // Existing date filtering helper function
  const isWithinDateRange = (dateString, filter) => {
    const date = new Date(dateString);
    const now = new Date();
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const oneYear = 365 * 24 * 60 * 60 * 1000;

    switch (filter) {
      case 'week':
        return (now - date) <= oneWeek;
      case 'month':
        return (now - date) <= oneMonth;
      case 'year':
        return (now - date) <= oneYear;
      default:
        return true;
    }
  };

  // Existing sorting handler
  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...productData];

    switch (option) {
      case 'name-asc':
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name-desc':
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'price-high':
        sortedData.sort((a, b) => b.price - a.price);
        break;
      case 'price-low':
        sortedData.sort((a, b) => a.price - b.price);
        break;
      case 'rating-high':
        sortedData.sort((a, b) => b.rating - a.rating);
        break;
      case 'rating-low':
        sortedData.sort((a, b) => a.rating - b.rating);
        break;
      default:
        break;
    }

    setProductData(sortedData);
  };

  // Filter products
  const filteredProducts = productData.filter(product => 
    product.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
    isWithinDateRange(product.dateAdded, dateFilter)
  );

  if (loading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-4">Error: {error}</div>;
  }

  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">

      {/* Filters Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Name Search */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search Product
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Sort Options */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Sort By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="">Select Sort Option</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="price-high">Price (High-Low)</option>
              <option value="price-low">Price (Low-High)</option>
              <option value="rating-high">Rating (High-Low)</option>
              <option value="rating-low">Rating (Low-High)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="all">All Time</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#0D182E] p-4  rounded-lg mb-6 ">
          {progressData.map((item, index) => (
            <div key={index} className="mb-4">
              <div className="flex justify-between mb-2">
                <span className="font-[GeneralSansMedium] text-[white]">{item.label}</span>
                <span className="font-[GeneralSansMedium] text-[white]">{item.value}</span>
              </div>
              <div className="w-full bg-[#DC3DF7] rounded-full h-3">
                <div 
                  className=" bg-[#3DE9DC] h-3 rounded-full" 
                  style={{width: `${item.value}%`}}
                ></div>
              </div>
            </div>
          ))}
        </div>

      {/* Products Table */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-white mb-4">Products/Services</h2>
        <table className="min-w-full">
          <thead className="hidden sm:table-header-group">
            <tr className="bg-[#1a2942]">
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Product</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Business Name</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Price</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Reviews</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Rating</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {filteredProducts.map((product) => (
              <tr key={product.id} className="hover:bg-[#1a2942] flex flex-col sm:table-row mb-4 sm:mb-0">
                <td className="px-4 sm:px-6 py-4 flex items-center">
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 rounded-lg overflow-hidden">
                      <img src={product.image} alt={product.name} className="w-full h-full object-cover" />
                    </div>
                    <div>
                      <p className="font-['GeneralSansRegular'] text-white">{product.name}</p>
                    </div>
                  </div>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-white">{product.businessName}</span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">${product.price}</span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-white">{product.reviewCount} <span className="sm:hidden">Reviews</span></span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <div className="w-full bg-[#DC3DF7] rounded-full h-2">
                    <div 
                      className="bg-[#3DE9DC] h-2 rounded-full" 
                      style={{ width: `${product.rating}%` }}
                    ></div>
                  </div>
                  <span className="text-white text-sm ml-2 font-['GeneralSansRegular']">{product.rating}%</span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4 relative">
                  <div className="relative">
                    <button
                      onClick={() => setOpenDropdownId(openDropdownId === product.id ? null : product.id)}
                      className="w-8 h-8 flex items-center justify-center rounded-lg hover:bg-white/10 transition-colors"
                    >
                      <MoreVertical size={20} className="text-white" />
                    </button>
                    
                    {openDropdownId === product.id && (
                      <div className="absolute right-0 mt-2 w-48 bg-[#1A2E56] rounded-lg shadow-lg z-10">
                        <div className="py-1">
                          <button
                            onClick={() => handlePause(product.id)}
                            className="w-full text-left px-4 py-2 text-white hover:bg-white/10 font-['GeneralSansRegular']"
                          >
                            Pause
                          </button>
                          <button
                            onClick={() => handleEdit(product.id)}
                            className="w-full text-left px-4 py-2 text-white hover:bg-white/10 font-['GeneralSansRegular']"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDelete(product.id)}
                            className="w-full text-left px-4 py-2 text-red-500 hover:bg-white/10 font-['GeneralSansRegular']"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* No Results Message */}
        {filteredProducts.length === 0 && (
          <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
            No products found matching the selected filters.
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsPage;