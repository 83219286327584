import React, { useState } from 'react';
import { Search, ArrowUpDown, Calendar, FileText } from 'lucide-react';
import ProfileImage from './assets/images/profile.png';
import logo from "./assets/images/loogfinal.png";

const InvoiceHistoryComponent = () => {
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [invoiceData, setInvoiceData] = useState([
    { 
      id: 'INV-001',
      type: 'Referral',
      date: '2024-01-15',
      amount: '$250.00',
      numericAmount: 250.00,
      status: 'Paid',
      businessName: 'Acme Corp',
      businessLocation: 'NewYork, NY',
      businessLogo: logo,
      items: 'Website Review, SEO Analysis',
      senderName: 'Kat Larsson',
      referredPerson: 'John Anderson',
      email: 'KatLarsson@gmail.com',
      phone: '(469) 532 2382',
      industry: 'Technology Services',
      backgroundColor: 'bg-[#527DE6]'
    },
    { 
      id: 'INV-002',
      type: 'Review',
      date: '2024-02-20',
      amount: '$175.50',
      numericAmount: 175.50,
      status: 'Pending',
      businessName: 'TechStart Inc',
      businessLocation: 'Boston, MA',
      businessLogo: logo,
      items: 'Mobile App Review',
      senderName: 'Mike Johnson',
      email: 'mike@techstart.com',
      phone: '(469) 532 2383',
      industry: 'Software Development',
      backgroundColor: 'bg-[#65D4B0]'
    },
    { 
      id: 'INV-003',
      type: 'Referral',
      date: '2024-03-10',
      amount: '$300.75',
      numericAmount: 300.75,
      status: 'Paid',
      businessName: 'Global Services',
      businessLocation: 'Chicago, IL',
      businessLogo: logo,
      items: 'Cloud Architecture Review',
      senderName: 'Sarah Smith',
      referredPerson: 'Emma Wilson',  
      email: 'sarah@global.com',
      phone: '(469) 532 2384',
      industry: 'Consulting',
      backgroundColor: 'bg-[#DB005F]'
    }
  ]);

  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...invoiceData];

    switch (option) {
      case 'date-asc':
        sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        break;
      case 'date-desc':
        sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;
      case 'amount-asc':
        sortedData.sort((a, b) => a.numericAmount - b.numericAmount);
        break;
      case 'amount-desc':
        sortedData.sort((a, b) => b.numericAmount - a.numericAmount);
        break;
      case 'business-asc':
        sortedData.sort((a, b) => a.businessName.localeCompare(b.businessName));
        break;
      case 'business-desc':
        sortedData.sort((a, b) => b.businessName.localeCompare(a.businessName));
        break;
      case 'status-asc':
        sortedData.sort((a, b) => a.status.localeCompare(b.status));
        break;
      case 'status-desc':
        sortedData.sort((a, b) => b.status.localeCompare(a.status));
        break;
      case 'id-asc':
        sortedData.sort((a, b) => a.id.localeCompare(b.id));
        break;
      case 'id-desc':
        sortedData.sort((a, b) => b.id.localeCompare(a.id));
        break;
      default:
        break;
    }

    setInvoiceData(sortedData);
  };

  const isWithinDateRange = (dateString, filter) => {
    if (!dateString || filter === 'all') return true;
    const date = new Date(dateString);
    const now = new Date();
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const oneYear = 365 * 24 * 60 * 60 * 1000;

    switch (filter) {
      case 'week':
        return (now - date) <= oneWeek;
      case 'month':
        return (now - date) <= oneMonth;
      case 'year':
        return (now - date) <= oneYear;
      default:
        return true;
    }
  };

  const filteredInvoices = invoiceData.filter(invoice => {
    const searchTerm = nameFilter.toLowerCase();
    return (
      (!searchTerm ||
        invoice.businessName.toLowerCase().includes(searchTerm) ||
        invoice.senderName.toLowerCase().includes(searchTerm) ||
        invoice.id.toLowerCase().includes(searchTerm) ||
        invoice.industry.toLowerCase().includes(searchTerm)) &&
      isWithinDateRange(invoice.date, dateFilter)
    );
  });

  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      {/* Filters Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Search Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search Invoice
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name or business"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Sort Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Sort By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="">Select Sort Option</option>
              <option value="date-asc">Date (Oldest First)</option>
              <option value="date-desc">Date (Newest First)</option>
              <option value="amount-asc">Amount (Low to High)</option>
              <option value="amount-desc">Amount (High to Low)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="all">All Time</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Table */}
       {/* Invoice Table */}
       <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-white mb-4">Invoice History</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="hidden sm:table-header-group">
              <tr className="bg-[#1a2942]">
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Invoice Details</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Business Details</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Industry</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Amount</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {filteredInvoices.map((invoice) => (
                <tr key={invoice.id} className="hover:bg-[#1a2942] flex flex-col sm:table-row mb-4 sm:mb-0">
                  <td className="px-4 sm:px-6 py-4 flex items-center">
                    <div className="flex items-center space-x-4">
                      <div className={`w-10 h-10 transform rotate-45 border border-[#1A2E56] rounded-[10px] overflow-hidden ${invoice.backgroundColor}`}>
                        <div className="w-full h-full -rotate-45 flex items-center justify-center">
                          <FileText className="text-white" size={20} />
                        </div>
                      </div>
                      <div>
                        <p className="font-['GeneralSansRegular'] text-white">{invoice.id}</p>
                        {invoice.type === 'Referral' && (
                          <p className="text-sm text-[#3DE9DC] font-['GeneralSansRegular']">
                            Referred: {invoice.referredPerson}
                          </p>
                        )}
                        <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{invoice.date}</p>
                        <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{invoice.type} Invoice</p>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-lg overflow-hidden">
                        <img 
                          src={invoice.businessLogo} 
                          alt={`${invoice.businessName} logo`}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <span className="font-['GeneralSansRegular'] text-white">{invoice.businessName}</span>
                    </div>
                    <p className="font-['GeneralSansRegular'] text-xs ml-14 text-white">{invoice.businessLocation}</p>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <span className="font-['GeneralSansRegular'] text-white">
                      {invoice.industry}
                    </span>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">{invoice.amount}</span>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <span className={`inline-flex px-3 py-1 text-sm rounded-full font-['GeneralSansRegular'] ${
                      invoice.status === 'Paid' ? 'bg-[#65D4B0] text-white' : 
                      invoice.status === 'Pending' ? 'bg-[#FE8705] text-white' : 
                      'bg-[#DB005F] text-white'
                    }`}>
                      {invoice.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* No Results Message */}
          {filteredInvoices.length === 0 && (
            <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
              No invoices found matching the selected filters.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceHistoryComponent;