import React from "react";
import image from "./assets/images/zoho.png";
import image1 from "./assets/images/mailchimp.png";
import image3 from "./assets/images/salesforce.png";
import image4 from "./assets/images/monday.png";

const IntegrationsPage = () => {
  const integrations = [
    { name: "Zoho Mail", img: image },
    { name: "Mail Chimp", img: image1 },
    { name: "Salesforce", img: image3 },
    { name: "Monday.com", img: image4 },
  ];

  return (
    <div className="min-h-screen w-full pt-20 px-4 sm:px-6 lg:px-8 relative">
      <div className="max-w-full space-y-4 sm:space-y-8">
        {integrations.map((integration, index) => (
          <div
            key={index}
            className="flex flex-col sm:flex-row items-center gap-4 sm:space-x-6 bg-[#0D182E] rounded-2xl p-4 sm:p-7"
          >
            {/* Integration Image */}
            <div className="shrink-0 w-32 sm:w-auto h-12 sm:h-16 flex items-center justify-center">
              <img
                src={integration.img}
                alt={integration.name}
                className="max-w-full max-h-full object-contain"
              />
            </div>
            {/* Integration Name */}
            <div className="min-w-0 flex-1">
              <h2 className="text-lg sm:text-xl font-['GeneralSansBold'] text-white text-center sm:text-left truncate">
                {integration.name}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationsPage;