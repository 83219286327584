import React from "react";
import { useNavigate } from "react-router-dom";

const BLegalPolicy = () => {
  return (
    <div className="min-h-screen pt-20 px-4 sm:px-6 lg:px-8">
      {/* Full-width rounded section */}
      

      <div className="max-w-full bg-[#0D182E] rounded-lg shadow-md p-6 sm:p-8">
        <h1 className="text-3xl font-['VerminViva'] text-white mb-6 border-b-2  pb-4"         style={{
          borderImage: 'linear-gradient(105.41deg, #3DE9DC -17.48%, #889EE7 35.37%, #DC3DF7 99.28%)',
          borderImageSlice: 1,
        }}>
          Legal Policy
        </h1>

        <section className="mb-6">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            1. Scope of Legal Policy
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam 
            auctor, nunc eget ultricies tincidunt, velit velit bibendum velit, 
            vel bibendum sapien nunc vel lectus. Fusce euismod, nunc sit amet 
            aliquam lacinia, nisi enim lobortis enim, vel lacinia nunc enim 
            eget nunc.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            2. Compliance and Regulations
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
            pariatur.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            3. Intellectual Property Rights
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui 
            officia deserunt mollit anim id est laborum. Sed ut perspiciatis 
            unde omnis iste natus error sit voluptatem accusantium doloremque 
            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore 
            veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            4. Liability and Disclaimers
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit 
            aut fugit, sed quia consequuntur magni dolores eos qui ratione 
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem 
            ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia 
            non numquam eius modi tempora incidunt ut labore et dolore magnam 
            aliquam quaerat voluptatem.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            5. Dispute Resolution
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Ut enim ad minima veniam, quis nostrum exercitationem ullam 
            corporis suscipit laboriosam, nisi ut aliquid ex ea commodi 
            consequatur? Quis autem vel eum iure reprehenderit qui in ea 
            voluptate velit esse quam nihil molestiae consequatur, vel illum 
            qui dolorem eum fugiat quo voluptas nulla pariatur.
          </p>
        </section>

        <section className="">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            6. Contact Information
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            For any legal inquiries or concerns, please contact our legal 
            department at legal@example.com or call +1 (555) 123-4567.
          </p>
        </section>
      </div>
    </div>
  );
};

export default BLegalPolicy;