import React, { useState } from 'react';
import { ArrowLeft, ArrowRight, Building2, MapPin, Upload, FileText, Image, FileSpreadsheet, FileCheck2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const BusinessVerificationForm = () => {
  const [formData, setFormData] = useState({
    acnNumber: '',
    businessWebsite: '',
    directorName1: '',
    directorName2: '',
    adminPhone: '',
    businessPhone: '',
    facebookLink: '',
    instagramLink: '',
    adminEmail: '',
    clientContactEmail: '',
    openingHours: '',
    businessImages: [],
    fileType: '',
    uploadedFiles: []
  });

  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);

  const fileTypeOptions = [
    {
      value: 'businessLicense',
      label: 'Government issued Document',
      icon: FileCheck2,
      description: 'An acceptable document, issued by an authorised government body, that includes the name of your business and the address of your principal office in the country in which you are a resident or the country in which your business is incorporated or organised.'
    },
    {
      value: 'taxDocuments',
      label: 'Business Name Evidence',
      icon: FileText,
      description: 'A copy of your certificate of business name registeration issued by ASIC (when operating under a business or trading name)'
    },
    {
      value: 'companyProof',
      label: 'Company extract issued by ASIC',
      icon: Building2,
      description: 'A copy of the ASIC-issued company extract'
    }
  ];

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileUpload = (files) => {
    if (!formData.fileType) {
      alert('Please select a file type before uploading');
      return;
    }

    const fileArray = Array.from(files);
    setFormData(prevState => ({
      ...prevState,
      uploadedFiles: [...prevState.uploadedFiles, ...fileArray]
    }));
  };

  const removeFile = (indexToRemove) => {
    setFormData(prevState => ({
      ...prevState,
      uploadedFiles: prevState.uploadedFiles.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleFileTypeChange = (selectedType) => {
    // Reset uploaded files when file type changes
    setFormData(prevState => ({
      ...prevState,
      fileType: selectedType,
      uploadedFiles: []
    }));
  };
  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold"
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize: 8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>
        </div>
      </div>

      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-5xl border border-white/20 shadow-2xl mb-7">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Business Profile Verification</h2>
        
        <form className="space-y-6 -mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="relative">
              <label 
                htmlFor="acnNumber" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Australian Company Number (ACN)
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                <Building2 className="h-5 w-5 text-white/50 mt-6" />
              </div>
              <input
                id="acnNumber"
                type="text"
                value={formData.acnNumber}
                onChange={(e) => setFormData({ ...formData, acnNumber: e.target.value })}
                placeholder="Enter your ACN"
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="businessWebsite" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Business Website
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                <MapPin className="h-5 w-5 text-white/50 mt-6" />
              </div>
              <input
                id="businessWebsite"
                type="text"
                value={formData.businessWebsite}
                onChange={(e) => setFormData({ ...formData, businessWebsite: e.target.value })}
                placeholder="Enter your business website"
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="directorName1" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Full Name of Director 1
              </label>
              <input
                id="directorName1"
                type="text"
                value={formData.directorName1}
                onChange={(e) => setFormData({ ...formData, directorName1: e.target.value })}
                placeholder="Enter director's full name"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="directorName2" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Full Name of Director 2
              </label>
              <input
                id="directorName2"
                type="text"
                value={formData.directorName2}
                onChange={(e) => setFormData({ ...formData, directorName2: e.target.value })}
                placeholder="Enter second director's full name"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="adminPhone" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Admin Phone Number
              </label>
              <input
                id="adminPhone"
                type="tel"
                value={formData.adminPhone}
                onChange={(e) => setFormData({ ...formData, adminPhone: e.target.value })}
                placeholder="Enter admin contact number"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="businessPhone" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Business Phone Number
              </label>
              <input
                id="businessPhone"
                type="tel"
                value={formData.businessPhone}
                onChange={(e) => setFormData({ ...formData, businessPhone: e.target.value })}
                placeholder="Enter business contact number"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="facebookLink" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Facebook Profile/Page Link
              </label>
              <input
                id="facebookLink"
                type="url"
                value={formData.facebookLink}
                onChange={(e) => setFormData({ ...formData, facebookLink: e.target.value })}
                placeholder="Enter Facebook link"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="instagramLink" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Instagram Profile Link
              </label>
              <input
                id="instagramLink"
                type="url"
                value={formData.instagramLink}
                onChange={(e) => setFormData({ ...formData, instagramLink: e.target.value })}
                placeholder="Enter Instagram link"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="adminEmail" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Admin Contact Email
              </label>
              <input
                id="adminEmail"
                type="email"
                value={formData.adminEmail}
                onChange={(e) => setFormData({ ...formData, adminEmail: e.target.value })}
                placeholder="Enter admin contact email"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="clientContactEmail" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Client Contact Email
              </label>
              <input
                id="clientContactEmail"
                type="email"
                value={formData.clientContactEmail}
                onChange={(e) => setFormData({ ...formData, clientContactEmail: e.target.value })}
                placeholder="Enter email for clients"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative md:col-span-2">
              <label 
                htmlFor="openingHours" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Opening Hours
              </label>
              <input
                id="openingHours"
                type="text"
                value={formData.openingHours}
                onChange={(e) => setFormData({ ...formData, openingHours: e.target.value })}
                placeholder="Enter your business opening hours"
                className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>
          </div>

          {/* File Upload */}
          <div className="mt-8 bg-white/5 border border-white/20 rounded-xl p-8">
        {/* File Type Selection */}
        <div className="mb-6">
          <label className="block text-sm text-white/80 mb-4 font-['GeneralSansMedium']">
            Select Document Type to Upload
          </label>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
            {fileTypeOptions.map((option) => (
              <button
                key={option.value}
                type="button"
                onClick={() => handleFileTypeChange(option.value)}
                className={`flex flex-col items-center p-4 rounded-lg transition-all duration-200 
                  ${formData.fileType === option.value 
                    ? 'bg-white/20 border-2 border-white/50 scale-105' 
                    : 'hover:bg-white/10 border border-white/10'}`}
              >
                <option.icon className={`h-8 w-8 mb-2 ${formData.fileType === option.value ? 'text-white' : 'text-white/60'}`} />
                <span className="text-sm font-['GeneralSansMedium'] text-white/80">{option.label}</span>
              </button>
            ))}
          </div>
        </div>

        {/* File Type Description */}
        {formData.fileType && (
          <div className="bg-white/10 rounded-lg p-4 mb-6 text-sm text-white/80 font-['GeneralSansRegular']">
            <p>
              {fileTypeOptions.find(option => option.value === formData.fileType).description}
            </p>
          </div>
        )}

        {/* File Upload Area */}
        <div 
          className={`border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
            ${isDragging 
              ? 'border-white/50 bg-white/10 scale-[1.02]' 
              : 'border-white/30 hover:border-white/50 bg-white/5'}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.getElementById('fileInput').click()}
        >
          <input
            id="fileInput"
            type="file"
            className="hidden"
            multiple
            onChange={(e) => handleFileUpload(e.target.files)}
          />
          <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
            <Upload className="h-8 w-8 text-blue-950" />
          </div>
          <p className="text-sm font-['GeneralSansMedium'] text-gray-300 mb-1">
            {formData.fileType 
              ? `Upload ${fileTypeOptions.find(option => option.value === formData.fileType).label}` 
              : 'Select a document type first'}
          </p>
          <p className="text-xs text-gray-500">
            PDF, DOCX, JPG accepted (max 10MB per file)
          </p>
        </div>

        {/* File Preview */}
        {formData.uploadedFiles.length > 0 && (
          <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
            {formData.uploadedFiles.map((file, index) => (
              <div key={index} className="relative">
                <div className="w-full h-24 bg-white/10 rounded-lg flex items-center justify-center">
                  <FileText className="h-8 w-8 text-white/60" />
                </div>
                <p className="text-xs text-white/80 mt-2 truncate">{file.name}</p>
                <button
                  type="button"
                  onClick={() => removeFile(index)}
                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 
                             flex items-center justify-center text-xs hover:bg-red-600"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between items-center mt-12 pt-6 border-t border-white/20">
            <button
              type="button"
              onClick={() => navigate('/UploadMedia')}
              className="flex items-center px-6 py-3 border-2 border-white/30 text-white rounded-lg 
                         font-['GeneralSansMedium'] hover:bg-white/10 transition-all duration-200"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back
            </button>
            <button
              type="submit"
              onClick={() => navigate('/BusinessReviewandConfirm')}
              className="flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 
                         text-white rounded-lg font-['GeneralSansMedium'] hover:opacity-90 
                         transition-all duration-200 shadow-lg hover:shadow-xl 
                         hover:scale-[1.02]"
            >
              Next Step
              <ArrowRight className="w-4 h-4 ml-2" />
            </button>
          </div>
        </form>
        <div className="mt-9 text-center">
          <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
            Already have an account?
          </span>
          <button 
            onClick={() => navigate('/SignIn')}
            className="text-white font-['GeneralSansMedium'] hover:underline"
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessVerificationForm;