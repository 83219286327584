import React, { useState, useEffect } from 'react';
import { Search, ArrowUpDown, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const TeamPage = () => {
  const navigate =useNavigate();
  const progressData = [
    { label: 'Quality / Interaction Journey', value: 75 },
    { label: 'Performance / Interaction Journey', value: 60 },
    { label: 'Easy To Use / Interaction Journey', value: 90 },
    { label: 'Durability / Interaction Journey', value: 60 },
  ];
  const accessToken = localStorage.getItem('accessToken');
  const [teamData, setTeamData] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/get_employee_detail', {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });
        if(response.status=='401'){
          localStorage.removeItem('accessToken');
          navigate('/signin')
        }
        
        if (!response.ok) {
          throw new Error('Failed to fetch team data');
        }
        
        const data = await response.json();
        const transformedData = data.map(employee => ({
          id: employee.id,
          image: employee.employee_profiles,
          name: employee.employee_name,
          role: employee.designation,
          joinDate: employee.working_since,
          reviewScore: 0,
          totalReviews: 0
        }));
        
        setTeamData(transformedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  // Date filtering helper function
  const isWithinDateRange = (dateString, filter) => {
    const date = new Date(dateString);
    const now = new Date();
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const oneYear = 365 * 24 * 60 * 60 * 1000;

    switch (filter) {
      case 'week':
        return (now - date) <= oneWeek;
      case 'month':
        return (now - date) <= oneMonth;
      case 'year':
        return (now - date) <= oneYear;
      default:
        return true;
    }
  };

  // Sorting handler
  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...teamData];

    switch (option) {
      case 'name-asc':
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name-desc':
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'reviews-high':
        sortedData.sort((a, b) => b.totalReviews - a.totalReviews);
        break;
      case 'reviews-low':
        sortedData.sort((a, b) => a.totalReviews - b.totalReviews);
        break;
      case 'rating-high':
        sortedData.sort((a, b) => b.reviewScore - a.reviewScore);
        break;
      case 'rating-low':
        sortedData.sort((a, b) => a.reviewScore - b.reviewScore);
        break;
      default:
        break;
    }

    setTeamData(sortedData);
  };

  // Filter team members
  const filteredTeam = teamData.filter(member => 
    member.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
    isWithinDateRange(member.joinDate, dateFilter)
  );

  if (loading) {
    return <div className="text-center text-white p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-4">Error: {error}</div>;
  }

  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search By Name
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Sort Options */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Sort By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="">Select Sort Option</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="reviews-high">Reviews (High-Low)</option>
              <option value="reviews-low">Reviews (Low-High)</option>
              <option value="rating-high">Rating (High-Low)</option>
              <option value="rating-low">Rating (Low-High)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="all">All Time</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full  bg-[#0D182E] p-4  rounded-lg mb-6">
        {progressData.map((item, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-between mb-2">
              <span className="font-[GeneralSansMedium] text-[white]">{item.label}</span>
              <span className="font-[GeneralSansMedium] text-[white]">{item.value}</span>
            </div>
            <div className="w-full bg-[#DC3DF7] rounded-full h-3">
              <div 
                className="bg-[#3DE9DC] h-3 rounded-full" 
                style={{width: `${item.value}%`}}
              ></div>
            </div>
          </div>
        ))}
      </div>

      {/* Team Members Table */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-white mb-4">Our Team</h2>
        <table className="min-w-full">
          <thead className="hidden sm:table-header-group">
            <tr className="bg-[#1a2942]">
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Team Member</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Role</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Reviews</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Rating</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {filteredTeam.map((member) => (
              <tr key={member.id} className="hover:bg-[#1a2942] flex flex-col sm:table-row mb-4 sm:mb-0">
                <td className="px-4 sm:px-6 py-4 flex items-center">
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 rounded-lg overflow-hidden">
                      <img src={member.image} alt={member.name} className="w-full h-full object-cover" />
                    </div>
                    <div>
                      <p className="font-['GeneralSansRegular'] text-white">{member.name}</p>
                    </div>
                  </div>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">{member.role}</span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-white">{member.totalReviews}<span className='sm:hidden'>Reviews</span></span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <div className="flex items-center">
                    <span className="text-white text-sm font-['GeneralSansRegular']">{member.reviewScore}%</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* No Results Message */}
        {filteredTeam.length === 0 && (
          <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
            No team members found matching the selected filters.
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamPage;