import React, { useState } from 'react';
import video1 from "./assets/images/reviewvideo.mp4";
import logo1 from "./assets/images/avatar2.png";
import sliderLogo from "./assets/images/loogfinal.png";
import anim1 from "./assets/images/anim1.gif";
import animDec from "./assets/images/animdec.gif";
import { Upload } from 'lucide-react';
const SliderReviewSection = () => {
  const [productRatings, setProductRatings] = useState({
    quality: 5,
    performance: 5,
    value: 5,
    timing: 5
  });

  const [teamRatings, setTeamRatings] = useState({
    extracare: 5,
    expertise: 5,
    responsiveness: 5,
    professionalism: 5
  });

  const [businessRatings, setBusinessRatings] = useState({
    layout: 5,
    delivery: 5,
    pricevsvalue: 5
  });

  const [selectedSource, setSelectedSource] = useState(null);
  const [referralName, setReferralName] = useState('');
  const [comment, setComment] = useState('');

  const sourceOptions = [
    'Facebook',
    'Instagram',
    'LinkedIn',
    'Twitter',
    'Google Search',
    'YouTube',
    'Friend/Family Referral',
    'Other'
  ];

  const pickImage = () => {
    alert('Image picker would be implemented here');
  };

  const SliderSection = ({ title, ratings, setRatings, questions }) => (
    <div className="bg-[#0D182E] rounded-xl p-4 md:p-8 shadow-md border border-gray-700 space-y-4 md:space-y-6">
      <h3 className="text-xl md:text-2xl text-white mb-4 md:mb-6 text-left" style={{ fontFamily: 'GeneralSansSemibold' }}>
        {title}
      </h3>
      <div className="space-y-8 md:space-y-12">
        {Object.entries(questions).map(([key, question]) => (
          <div key={key} className="space-y-6 md:space-y-8">
            <label className="text-white text-base md:text-lg text-center block px-2 md:px-4" style={{ fontFamily: 'GeneralSansRegular' }}>
              {question}
            </label>
            <div className="relative flex items-center justify-center space-x-2 md:space-x-4 px-2 md:px-8">
              {/* Left animation - hidden on mobile, shown on larger screens */}
              <div className=" md:flex flex-col items-center">
                <img 
                  src={animDec} 
                  alt="Left animation" 
                  className="w-10 h-10 md:w-16 md:h-16 object-contain rounded-2xl"
                />
                <span className="hidden md:flex text-white text-xs md:text-sm mt-2 text-center">
                  Work in Progress
                </span>
              </div>
              
              <div className="flex-1 relative">
                <div className="h-12 md:h-8 flex items-center">
                  <input
                    type="range"
                    min="1"
                    max="10"
                    value={ratings[key]}
                    onChange={(e) => setRatings({ ...ratings, [key]: parseInt(e.target.value) })}
                    className="w-full h-2 md:h-3 bg-gray-700 rounded-lg appearance-none cursor-pointer slider-thumb"
                    style={{
                      background: `linear-gradient(to right, #959DB0 0%, #959DB0 ${(ratings[key] - 1) * 11.11}%, #1A2747 ${(ratings[key] - 1) * 11.11}%, #1A2747 100%)`,
                      '--slider-thumb': `url(${sliderLogo})`
                    }}
                  />
                </div>
                <span className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 text-white min-w-[2rem] text-center bg-[#959DB0] rounded-lg px-2 py-0.5 text-sm">
                  {ratings[key]}
                </span>
              </div>

              {/* Right animation - hidden on mobile, shown on larger screens */}
              <div className=" md:flex flex-col items-center">
                <img 
                  src={anim1} 
                  alt="Right animation" 
                  className="w-10 h-10 md:w-16 md:h-16 object-contain rounded-2xl"
                />
                <span className="hidden md:flex text-white text-xs md:text-sm mt-2 text-center">
                  Exceeded Expectations
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="w-full p-3 md:p-8 space-y-6 md:space-y-8 mt-6 md:mt-10" style={{fontFamily: 'GeneralSansRegular'}}>
      {/* Video Container */}
      <div className="border-2 md:border-4 border-white rounded-xl overflow-hidden">
        <div className="relative w-full h-64 md:h-96">
          <video 
            className="w-full h-full object-cover"
            autoPlay 
            muted 
            loop
          >
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          <div className="absolute bottom-4 md:bottom-8 left-4 md:left-8 flex items-center space-x-3 md:space-x-4">
            <img 
              src={logo1} 
              alt="Company Logo" 
              className="h-12 md:h-16 w-auto"
            />
            
            <div className="text-white w-32 md:w-44">
              <h2 className="text-lg md:text-2xl font-bold">Crown Casino Melborne</h2>
            </div>
          </div>
        </div>
      </div>

      {/* Rating Sections */}
      <SliderSection
        title="Benefit"
        ratings={productRatings}
        setRatings={setProductRatings}
        questions={{
          quality: "How do you rate the quality of the Products/Services received?",
          performance: "How do you rate the performace of Products/Services received?",
          value: "How do you rate using our Products/Services?",
          timing: "Did the Products/Services meet your timing requirement?"
        }}
      />

      <SliderSection
        title="Culture"
        ratings={teamRatings}
        setRatings={setTeamRatings}
        questions={{
          expertise: "How do you rate our Team's expertise?",
          extracare: "How do you rate our Team's extra care?",
          responsiveness: "How do your rate our Team responsiveness?",
          professionalism: "How do you rate our Team professionalism?"
        }}
      />

      <SliderSection
        title="Operation"
        ratings={businessRatings}
        setRatings={setBusinessRatings}
        questions={{
          layout: "How do you rate our Busines layout?",
          delivery: "How do you rate our delivery time?",
          pricevsvalue: "How do you rate the amount paid vs the value of our offering?"
        }}
      />
      {/* Source Selection - Modified for 3 columns */}
      <div className="bg-[#0D182E] rounded-xl p-4 md:p-8 shadow-md border border-gray-700">
        <h3 className="text-base md:text-lg text-white mb-4 md:mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          How did you hear about us?
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-3 md:gap-4">
          {sourceOptions.map((option, index) => (
            <div key={index} className="flex flex-col">
              <button
                onClick={() => setSelectedSource(option)}
                className={`w-full p-3 md:p-4 rounded-lg border-2 flex justify-between items-center bg-[#B7BDCA] ${
                  selectedSource === option 
                    ? 'border-[#DC3DF7] bg-[#DC3DF7]' 
                    : 'border-[#B7BDCA] hover:border-[#DC3DF7]'
                }`}
              >
                <span className="text-[#0D182E] text-sm md:text-base">{option}</span>
                <div className={`w-5 h-5 md:w-6 md:h-6 rounded-full border-2 flex items-center justify-center ${
                  selectedSource === option 
                    ? 'border-[#DC3DF7] bg-[#DC3DF7]' 
                    : 'border-[#0D182E]'
                }`}>
                  {selectedSource === option && (
                    <div className="w-full p-2 md:p-2.5 bg-[#DC3DF7] rounded-full border-white border-2" />
                  )}
                </div>
              </button>
              
              {selectedSource === 'Friend/Family Referral' && option === 'Friend/Family Referral' && (
                <div className="mt-3 md:mt-4 md:col-span-3">
                  <input
                    type="text"
                    value={referralName}
                    onChange={(e) => setReferralName(e.target.value)}
                    placeholder="Please enter the name of who referred you"
                    className="w-full p-3 md:p-4 bg-[#1A2747] text-white text-sm md:text-base rounded-lg border border-gray-700 focus:outline-none focus:border-[#DC3DF7]"
                    style={{ fontFamily: 'GeneralSansRegular' }}
                  />
                </div>
              )}
              {selectedSource === 'Other' && option === 'Other' && (
                <div className="mt-3 md:mt-4 md:col-span-3">
                  <input
                    type="text"
                    value={referralName}
                    onChange={(e) => setReferralName(e.target.value)}
                    placeholder="Please enter who referred you"
                    className="w-full p-3 md:p-4 bg-[#1A2747] text-white text-sm md:text-base rounded-lg border border-gray-700 focus:outline-none focus:border-[#DC3DF7]"
                    style={{ fontFamily: 'GeneralSansRegular' }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Comments Section */}
      <div className="bg-[#0D182E] rounded-xl p-4 md:p-8 shadow-md border border-gray-700">
        <h3 className="text-base md:text-lg text-white mb-4 md:mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          Let The community know about your personal experience
        </h3>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Enter Here"
          className="w-full h-24 md:h-32 p-3 md:p-4 bg-[#1A2747] text-white text-sm md:text-base rounded-lg border border-gray-700 focus:outline-none focus:border-blue-500 resize-none"
          style={{ fontFamily: 'GeneralSansRegular' }}
        />
      </div>

      {/* File Upload Section */}
      <div className="bg-[#0D182E] rounded-xl p-4 md:p-8 shadow-md border border-gray-700">
        <h3 className="text-white text-base md:text-lg font-bold mb-4 md:mb-6 font-['GeneralSansMedium']">
          Any Attachements To Show ?
        </h3>
        <div 
          onClick={pickImage}
          className="border border-[#E2E8F0] rounded-xl md:rounded-2xl h-32 md:h-44 flex flex-col justify-center items-center cursor-pointer"
        >
          <div className="mb-3 md:mb-4">
            <Upload className="w-8 h-8 md:w-12 md:h-12 text-white" />
          </div>
          <p className="text-white text-sm md:text-base font-['GeneralSansMedium'] mb-1">
            Click to upload <span className="text-[#959DB0] text-xs md:text-sm">or drag and drop</span>
          </p>
          <p className="text-[#959DB0] text-xs md:text-sm">SVG, PNG, JPG or GIF (max. 800x400px)</p>
        </div>
      </div>

      {/* Submit Button */}
      <div className="text-center pt-2 md:pt-4">
        <button 
          className="bg-[#0D182E] text-white text-sm md:text-base font-[GenralSansRegular] py-2.5 md:py-3 px-6 md:px-8 rounded-lg transition-colors duration-300"
          style={{ fontFamily: 'GeneralSansMedium' }}
        >
          Submit
        </button>
      </div>

      <style jsx>{`
        .slider-thumb {
          margin: 0;
          padding: 0;
        }

        .slider-thumb::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 28px;
          height: 28px;
          background-image: var(--slider-thumb);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          margin-top: -13px;
          transform-origin: center;
          transition: transform 0.2s ease;
        }
        
        .slider-thumb::-moz-range-thumb {
          width: 28px;
          height: 28px;
          background-image: var(--slider-thumb);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          border: none;
          transform-origin: center;
          transition: transform 0.2s ease;
        }

        @media (min-width: 768px) {
          .slider-thumb::-webkit-slider-thumb {
            width: 40px;
            height: 40px;
            margin-top: -18px;
          }
          
          .slider-thumb::-moz-range-thumb {
            width: 40px;
            height: 40px;
          }
        }

        .slider-thumb::-webkit-slider-thumb:active,
        .slider-thumb::-moz-range-thumb:active {
          transform: scale(1.1);
        }

        .slider-thumb::-webkit-slider-runnable-track {
          height: 3px;
          border-radius: 1.5px;
        }
        
        .slider-thumb::-moz-range-track {
          height: 3px;
          border-radius: 1.5px;
        }
      `}</style>
    </div>
  );
};

export default SliderReviewSection;