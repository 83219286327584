import React, { useState } from 'react';
import { ChevronLeft, Upload , BarcodeIcon} from 'lucide-react';

const productServiceOptions = [
  { label: 'Product A', value: 'product-a' },
  { label: 'Product B', value: 'product-b' },
  { label: 'Service X', value: 'service-x' },
  { label: 'Service Y', value: 'service-y' }
];

const templateOptions = [
  { label: 'Template 1', value: 'template-1' },
  { label: 'Template 2', value: 'template-2' },
  { label: 'Template 3', value: 'template-3' }
];

const InputField = ({ label, value, onChangeText, multiline = false, placeholder, type = 'text' }) => (
  <div className="relative mb-6">
    <label 
      className={`absolute left-4 text-white font-medium bg-[#0D182E] px-1 z-10 
        ${value ? '-top-2 text-xs text-[#B7BDCA]' : '-top-3'}`}
    >
      {label}
    </label>
    <input 
      type={type}
      className={`w-full border border-[#959DB0] rounded-lg p-4 bg-[#0D182E] 
        text-white placeholder-[#959DB0] font-['GeneralSansRegular'] 
        ${multiline ? 'h-32' : 'h-16'}`}
      value={value}
      onChange={(e) => onChangeText(e.target.value)}
      placeholder={placeholder}
    />
  </div>
);

const Dropdown = ({ label, value, onValueChange, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative mb-6">
      <label 
        className={`absolute left-4 text-white font-medium bg-[#0D182E] px-1 z-10 
          ${value ? '-top-2 text-xs text-[#B7BDCA]' : '-top-3'}`}
      >
        {label}
      </label>
      <div 
        className="w-full border border-[#959DB0] rounded-lg bg-[#0D182E] text-white p-4 flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{items.find(item => item.value === value)?.label || 'Select'}</span>
        <ChevronLeft className="transform rotate-90" size={20} color="white" />
      </div>
      {isOpen && (
        <div className="absolute top-full left-0 right-0 bg-[#0D182E] rounded-lg mt-1 z-50 border border-[#4A5568]">
          {items.map((item) => (
            <div 
              key={item.value} 
              className="p-4 border-b border-[#4A5568] last:border-b-0"
              onClick={() => {
                onValueChange(item.value);
                setIsOpen(false);
              }}
            >
              <span className="text-white">{item.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const FileUpload = ({ label, onFileSelect, acceptedTypes, previewUrl, icon: Icon }) => (
  <div className="mt-6">
    <h3 className="text-white text-lg font-bold mb-6 font-['GeneralSansMedium']">{label}</h3>
    <div className="relative">
      <input
        type="file"
        onChange={onFileSelect}
        accept={acceptedTypes}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      <div className="border border-[#E2E8F0] rounded-2xl h-44 flex flex-col justify-center items-center">
        {previewUrl ? (
          <img src={previewUrl} alt="Preview" className="h-full w-full object-contain rounded-2xl" />
        ) : (
          <>
            <div className="mb-4">
              <Icon className="w-12 h-12 text-white" />
            </div>
            <p className="text-white text-base font-['GeneralSansMedium'] mb-1">
              Click to upload <span className="text-[#959DB0] text-sm">or drag and drop</span>
            </p>
            <p className="text-[#959DB0] text-sm">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </>
        )}
      </div>
    </div>
  </div>
);

const SendLinkScreen = () => {
  const [formData, setFormData] = useState({
    productService: '',
    template: '',
    invoiceTotalAmount: '',
    confirmInvoiceTotalAmount: '',
    barcodeImage: null,
    barcodePreview: null,
    attachment: null,
    attachmentPreview: null
  });
  const [modalVisible, setModalVisible] = useState(false);

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleFileUpload = (field, previewField) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFormData({
        ...formData,
        [field]: file,
        [previewField]: fileUrl
      });
    }
  };

  const handleSubmit = () => {
    if (formData.invoiceTotalAmount !== formData.confirmInvoiceTotalAmount) {
      alert('Invoice total amounts do not match');
      return;
    }
    
    // Log the form data including files
    console.log('Submitting form data:', {
      ...formData,
      barcodeImage: formData.barcodeImage?.name,
      attachment: formData.attachment?.name
    });
    
    setModalVisible(true);
  };

  const calculateCashback = () => {
    const amount = parseFloat(formData.invoiceTotalAmount) || 0;
    return (amount * 0.15).toFixed(2);
  };

  return (
    <div className="bg-[#4D6F8F] min-h-screen pb-[102px] flex flex-col">
      <div className="px-4 pt-20 pb-8 flex justify-between items-center">
        <div className="flex items-center">
          {/* Header elements */}
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-2xl p-4 mx-4">
        <Dropdown
          label="Product/Services"
          value={formData.productService}
          onValueChange={(value) => handleInputChange('productService', value)}
          items={productServiceOptions}
        />
        
        <InputField
          label="Invoice Total Amount"
          value={formData.invoiceTotalAmount}
          onChangeText={(value) => handleInputChange('invoiceTotalAmount', value)}
          type="number"
          placeholder="Enter total amount"
        />
        
        <InputField
          label="Confirm Invoice Total Amount"
          value={formData.confirmInvoiceTotalAmount}
          onChangeText={(value) => handleInputChange('confirmInvoiceTotalAmount', value)}
          type="number"
          placeholder="Confirm total amount"
        />

        <InputField
          label="Amount"
          value={formData.confirmInvoiceTotalAmount}
          onChangeText={(value) => handleInputChange('cashbackamount', value)}
          type="number"
          placeholder="$ 15"
        />

        <FileUpload
          label="Upload Barcode Image"
          onFileSelect={handleFileUpload('barcodeImage', 'barcodePreview')}
          acceptedTypes="image/*"
          previewUrl={formData.barcodePreview}
          icon={BarcodeIcon}
        />

        <FileUpload
          label="Upload Attachment"
          onFileSelect={handleFileUpload('attachment', 'attachmentPreview')}
          acceptedTypes="image/*,.pdf,.doc,.docx"
          previewUrl={formData.attachmentPreview}
          icon={Upload}
        />
      </div>
      
      <button 
        className="bg-[#0D182E] py-5 rounded-xl w-[98%] mx-auto mt-8 flex items-center justify-center"
        onClick={handleSubmit}
      >
        <span className="text-white text-lg font-bold font-['GeneralSansMedium']">Submit</span>
      </button>

      {modalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#B7BDCA] rounded-2xl p-6 w-[30%] flex flex-col items-center">
            <h2 className="text-lg font-['GeneralSansSemibold'] text-[#0D182E] mb-4 -mt-2">
              Link Sent Successfully
            </h2>
            <p className="text-center mb-6 font-['GeneralSansRegular'] text-[#0D182E] text-base">
              Your link has been sent successfully. The recipient will receive it shortly.
            </p>
            <button 
              className="bg-[#0D182E] py-3.5 rounded-full w-[90%] flex items-center justify-center"
              onClick={() => setModalVisible(false)}
            >
              <span className="text-white text-base font-['GeneralSansMedium']">Continue</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendLinkScreen;