import React, { useState } from 'react';
import { Upload, ArrowLeft, ArrowRight, User, Calendar, Venus, Mars, PersonStandingIcon, PersonStanding } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const SettingsUserDetailsStep = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    profilePicture: []
  });
  const navigate = useNavigate();
  
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileUpload = (files) => {
    const fileArray = Array.from(files);
    setFormData(prevState => ({
      ...prevState,
      profilePicture: [...prevState.profilePicture, ...fileArray]
    }));
  };

  const removeImage = (indexToRemove) => {
    setFormData(prevState => ({
      ...prevState,
      profilePicture: prevState.profilePicture.filter((_, index) => index !== indexToRemove)
    }));
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-24">
        <div className="space-y-8 font-['GeneralSansRegular']">
        <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
  
        </div>
      </div>

      {/* Registration Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-5xl border border-white/20 shadow-2xl mb-7">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">User Details</h2>
        
        <form className="space-y-6 -mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="relative">
              <label 
                htmlFor="firstName" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                First Name
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                <User className="h-5 w-5 text-white/50 mt-6" />
              </div>
              <input
                id="firstName"
                type="text"
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                placeholder="Enter your first name"
                required
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="lastName" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Last Name
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                <User className="h-5 w-5 text-white/50 mt-6" />
              </div>
              <input
                id="lastName"
                type="text"
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                placeholder="Enter your last name"
                required
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative appearance-none">
              <label 
                htmlFor="gender" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Gender
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10 appearance-none">
                {formData.gender === 'Female' ? <PersonStanding className="h-5 w-5 text-white/50 mt-6 appearance-none" /> : 
                 formData.gender === 'Male' ? <PersonStandingIcon className="h-5 w-5 text-white/50 mt-6 appearance-none" /> : 
                 <User className="h-5 w-5 text-white/50 mt-6 appearance-none" />}
              </div>
              <select
                id="gender"
                value={formData.gender}
                onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                required
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 font-['GeneralSansRegular'] appearance-none"
              >
                <option value="" className="text-gray-700 bg-white">Select Gender</option>
                <option value="Male" className="text-gray-700 bg-white">Male</option>
                <option value="Female" className="text-gray-700 bg-white">Female</option>
                <option value="Other" className="text-gray-700 bg-white">Other</option>
              </select>
            </div>

            <div className="relative appearance-none">
              <label 
                htmlFor="dateOfBirth" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Date of Birth
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10 appearance-none">
                <Calendar className="h-5 w-5 text-white/50 mt-6 appearance-none" />
              </div>
              <input
                id="dateOfBirth"
                type="date"
                value={formData.dateOfBirth}
                onChange={(e) => setFormData({ ...formData, dateOfBirth: e.target.value })}
                required
                className="w-full h-12 px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular'] appearance-none"
              />
            </div>
          </div>
          
          {/* File Upload */}
          <div 
            className={`mt-8 border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
              ${isDragging 
                ? 'border-white/50 bg-white/10 scale-[1.02]' 
                : 'border-white/30 hover:border-white/50 bg-white/5'}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <input
              id="fileInput"
              type="file"
              className="hidden"
              accept="image/*"
              multiple
              onChange={(e) => handleFileUpload(e.target.files)}
            />
            <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
              <Upload className="h-8 w-8 text-blue-950" />
            </div>
            <p className="text-sm font-['GeneralSansMedium'] text-gray-300 mb-1">
              Click to upload or drag and drop profile picture
            </p>
            <p className="text-xs text-gray-500">
              SVG, PNG, JPG or GIF (max. 800x400px)
            </p>
          </div>

          {/* Image Preview */}
          {formData.profilePicture.length > 0 && (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
              {formData.profilePicture.map((image, index) => (
                <div key={index} className="relative">
                  <img 
                    src={URL.createObjectURL(image)} 
                    alt={`Profile Picture ${index + 1}`} 
                    className="w-full h-24 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 
                               flex items-center justify-center text-xs hover:bg-red-600"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Navigation Buttons */}
          
        </form>

      </div>
    </div>
  );
};

export default SettingsUserDetailsStep;