import React, { useState } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import authimage from "./assets/images/authimage.png";

const TwoFactorAuthSettings = () => {
  const navigate = useNavigate();
  const [textMessage2FA, setTextMessage2FA] = useState(false);
  const [authenticatorApp2FA, setAuthenticatorApp2FA] = useState(false);
  const [securityKey2FA, setSecurityKey2FA] = useState(false);
  const [showAuthenticatorModal, setShowAuthenticatorModal] = useState(false);

  // Custom Toggle Component
  const Toggle = ({ isChecked, onToggle }) => {
    return (
      <div 
        className={`w-14 h-7 rounded-full p-1 cursor-pointer transition-colors duration-300 
        ${isChecked ? 'bg-[#04A6A8]' : 'bg-gray-400'}`}
        onClick={onToggle}
      >
        <div 
          className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 
          ${isChecked ? 'translate-x-7' : 'translate-x-0'}`}
        ></div>
      </div>
    );
  };

  // Authenticator Modal Component
  const AuthenticatorModal = () => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div 
          className="bg-[#B7BDCA] rounded-lg w-96 p-6 relative"
        >
          <h2 
            className="text-center text-2xl mb-4 font-[GeneralSansSemibold]"
            style={{ color: '#111F3B' }}
          >
            2-FA Authentication
          </h2>
  
          <img 
            src={authimage}
            alt="Authentication Illustration" 
            className="mx-auto mb-4 w-32 h-32 object-contain"
          />
  
          <h3 
            className="text-lg text-center mb-2 font-[GeneralSansSemibold]"
            style={{ color: '#111F3B' }}
          >
          Authenticator App
          </h3>
  
          <p className="text-sm text-center font-[GeneralSansRegular] text-gray-700 mb-4">
          Connect the Authenticator app with Curtain to secure your account, you can choose app like Google authenticator, Duo App
          </p>
  
          <div className="flex justify-between space-x-4 font-[GeneralSansRegular]">
            <button 
              className="flex-1 border border-[#0D182E] text-[#0D182E] bg-transparent py-2 rounded-full"
              onClick={() => setShowAuthenticatorModal(false)}
            >
              Cancel
            </button>
            <button 
              className="flex-1 bg-[#0D182E] text-white py-2 rounded-full font-[GeneralSansRegular]"
              onClick={() => {
                setShowAuthenticatorModal(false);
                navigate('Authqr');
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#4D6F8F] pb-20">
      {/* Header */}
      <div className="flex items-center p-4 pt-20 pb-10">

      </div>

 
      {/* 2FA Options Container */}
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        {/* Text Message 2FA */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-white text-lg font-bold font-['GeneralSansMedium']">Authenticator App</h3>
            <Toggle 
              isChecked={authenticatorApp2FA} 
              onToggle={() => {
                setAuthenticatorApp2FA(!authenticatorApp2FA);
                setShowAuthenticatorModal(true);
              }} 
            />
          </div>
          <p className="text-[#959DB0] text-sm">
            Use a mobile authentication app to get a verification code to enter every time you log in to Curtain.
          </p>
        </div>

        {/* Authenticator App */}
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-white text-lg font-bold font-['GeneralSansMedium']">Text Message</h3>
            <Toggle 
              isChecked={textMessage2FA} 
              onToggle={() => {
                setTextMessage2FA(!textMessage2FA);
                if (!textMessage2FA) {
                  navigate('TextAuth'); // Redirect to a new component when text message 2FA is turned on
                }
              }} 
            />
          </div>
          <p className="text-[#959DB0] text-sm">
            Use a mobile authentication app to get a verification code to enter every time you log in to Curtain.
          </p>
        </div>

        {/* Security Key */}
        <div className="mb-2">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-white text-lg font-bold font-['GeneralSansMedium']">Security Key</h3>
            <Toggle 
              isChecked={securityKey2FA} 
              onToggle={() => setSecurityKey2FA(!securityKey2FA)} 
            />
          </div>
          <p className="text-[#959DB0] text-sm">
            Use a security key that inserts into your computer or syncs to your mobile device when you log in to Review Pay. You'll need to use a supported mobile device or web browser. Learn more
          </p>
        </div>
      </div>
      {showAuthenticatorModal && <AuthenticatorModal />}
    </div>
  );
};

export default TwoFactorAuthSettings;