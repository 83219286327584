import React, { useState, useEffect } from 'react';
import { 
  ChartBarIcon, 
  DollarSign, 
  Clock,
  Bookmark,
  Users,
  Eye,
  ShoppingCart,
  Heart,
  Wallet,
  WalletMinimal
} from 'lucide-react';
import profile from "./assets/images/profile.png";
import image1 from "./assets/images/cafe.png"
import image2 from "./assets/images/noburestaurent.png"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, PieChart, Pie, Cell } from 'recharts';
import { TrendingUp, Star, Activity } from 'lucide-react';
import ratinglogo from "./assets/images/loogfinal.png";
import SmallDial from './SmallDial';

// Keeping the existing data generation and sample data...
const generateData = () => {
  const data = [];
  const startDate = new Date('2012-01-01');
  const endDate = new Date('2013-02-27');
  
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 3)) {
    const baseValue = 20 + (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 7);
    const reviewRandomFactor = Math.random() * 2 - 1;
    const referralRandomFactor = Math.random() * 2 - 1;
    data.push({
      date: date.getTime(),
      reviews: parseFloat((baseValue + reviewRandomFactor).toFixed(2)),
      referrals: parseFloat((baseValue * 0.7 + referralRandomFactor).toFixed(2)) // Referrals slightly lower than reviews
    });
  }
  return data;
};

const sampleData = generateData();

const donutData = [
  { name: 'Spent', value: 75 },
  { name: 'Revenue', value: 25 }
];
const secondDonutData = [
  { name: 'Resturants', value: 40 },
  { name: 'Clothings', value: 35 },
  { name: 'Others', value: 25 }
];

const SECOND_COLORS = ['#65D4B0', '#DC3DF7', '#527DE6'];



const COLORS = ['#65D4B0', '#DC3DF7'];

const UserHome = () => {
  const [currentBusinessIndex, setCurrentBusinessIndex] = useState(0);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  
  const [timeRange, setTimeRange] = useState('1Y');
  
  const filterData = (range) => {
    const now = new Date('2013-02-27').getTime();
    let startDate;
    
    switch(range) {
      case '1 Week':
        startDate = new Date('2013-02-20').getTime();
        break;
      case '1 Month':
        startDate = new Date('2013-01-27').getTime();
        break;
      case '1 Year':
        startDate = new Date('2012-02-27').getTime();
        break;
      default:
        startDate = new Date('2012-02-27').getTime();
    }
    
    return sampleData.filter(item => item.date >= startDate && item.date <= now);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTooltipDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  useEffect(() => {
    const businessInterval = setInterval(() => {
      setCurrentBusinessIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 5000);

    const reviewInterval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 5000);

    return () => {
      clearInterval(businessInterval);
      clearInterval(reviewInterval);
    };
  }, []);

  const BusinessCard = ({ image, title, isReview = false }) => (
    <div className="bg-[#1a2942] rounded-lg p-4 sm:p-6 mb-4 flex-shrink-0 w-[350px] sm:w-[600px] flex">
      {/* Full Image on Left Side */}
      <div className="w-1/2 mr-4">
        <img
          src={image}
          alt="Business"
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
      
      {/* Text Content on Right Side */}
      <div className="w-2/3 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="font-['GeneralSansBold'] text-white text-lg">{title}</h3>
              <p className="font-['GeneralSansRegular'] text-gray-300 text-sm">
                Business Description
              </p>
            </div>
            <span className="font-['GeneralSansRegular'] text-gray-300 text-sm">2 days ago</span>
          </div>
          
          <p className="font-['GeneralSansRegular'] text-gray-300 mb-4 text-sm sm:text-base">
            Detailed description of the business or review. This provides more context about the item and gives users a comprehensive overview of what they're viewing.
          </p>
          
          {/* New "Review Now" button */}
          <button className="w-50 px-2 py-2 bg-[#B7BDCA] text-[#0D182E] rounded-lg hover:bg-[#B7BDCA] transition-colors font-['GeneralSansMedium'] mb-4">
            What do you think ?
          </button>
        </div>
  
        {isReview && (
          <button className="w-full px-4 py-2 border border-blue-500 text-blue-500 rounded-lg bg-transparent hover:bg-blue-500/10 transition-colors font-['GeneralSansMedium']">
            View Review Details
          </button>
        )}
      </div>
    </div>
  );
  
  const ReviewCard = ({ image, businessName, rating, review }) => {
    return (
      <div className="bg-[#1a2942] rounded-lg p-4 sm:p-6 mb-4 flex-shrink-0 w-[350px] sm:w-[600px] sm:flex block">
      {/* Business Image */}
      <div className="w-full sm:w-1/2 sm:mr-4 mb-4 sm:mb-0 justify-center text-center align-middle">
        <img
          src={image}
          alt="Business"
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
      
      {/* Review Content */}
      <div className="w-full sm:w-[350px] flex flex-col justify-between">
        <div>
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-4">
            <div className="w-full">
              <h3 className="font-['GeneralSansBold'] text-white text-lg text-center sm:text-left mb-4 sm:mb-0">
                {businessName}
              </h3>
              <div className="w-full sm:w-[450px] h-[100px] sm:h-[100px] sm:ml-48">
                <SmallDial currentPerformance={{ value: 70 }} />
              </div>
            </div>
          </div>
          
          <p className="font-['GeneralSansRegular'] text-gray-300 mb-4 text-sm sm:text-base">
            {review}
          </p>
          <span className="font-['GeneralSansRegular'] text-gray-300 text-sm block mb-3">
            2 days ago
          </span>
          
          {/* Change Review Button */}
          <button className="w-full px-4 py-2 bg-[#B7BDCA] text-[#0D182E] rounded-lg hover:bg-[#B7BDCA] transition-colors font-['GeneralSansMedium']">
            Change Review
          </button>
        </div>
      </div>
    </div>
  );
};

  const StatCard = ({ icon: Icon, title, value, description }) => (
    <div className="bg-[#0D182E] p-4 sm:p-6 rounded-lg h-full">
      <div className="flex items-center gap-4 mb-2">
        <div className="p-2 sm:p-3 bg-transparent border-2 rounded-full">
          <Icon className="w-4 h-4 sm:w-6 sm:h-6 text-white" />
        </div>
        <h3 className="text-sm sm:text-lg font-['GeneralSansSemibold'] text-slate-100">{title}</h3>
      </div>
      <p className="text-xl sm:text-3xl font-['GeneralSansMedium'] text-[#DC3DF7] mb-1">{value}</p>
    </div>
  );
  

  const businessData = [
    { image: image1, title: 'Business 1' },
    { image: image2, title: 'Business 2' },
    { image: image1, title: 'Business 3' },
    { image: image2, title: 'Business 4' },
  ];

  const reviewData = [
    { 
      image: image1, 
      businessName: 'Cafe Delight', 
      rating: 4, 
      review: 'Amazing coffee and friendly atmosphere! Loved the cozy ambiance and delicious pastries.'
    },
    { 
      image: image2, 
      businessName: 'Nobu Restaurant', 
      rating: 5, 
      review: 'Exceptional dining experience with top-notch service and incredible Japanese cuisine.'
    },
    { 
      image: image1, 
      businessName: 'Bistro Corner', 
      rating: 3, 
      review: 'Decent food, could use some improvement in presentation and variety.'
    },
    { 
      image: image2, 
      businessName: 'Gourmet Spot', 
      rating: 4, 
      review: 'Great selection of dishes and excellent wine pairing recommendations.'
    },
  ];

  return (
    <>
      {/* Stats Grid */}
      <div className="bg-[#65D4B0] m-6 p-5 mt-20 rounded-xl">
        <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-[#0D182E] mb-2">Analytics Overview</h2>
        <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 mb-6  rounded-2xl'>
          <StatCard 
            icon={Bookmark}
            title="Reviews"
            value="85%"
            description="Increase in monthly active users"
          />
          <StatCard 
            icon={Users}
            title="Refferals"
            value="2,345"
            description="Users active in the last 30 days"
          />
          <StatCard 
            icon={DollarSign}
            title="Revenue"
            value="$12,345"
            description="Total revenue this month"
          />
          <StatCard 
            icon={Eye}
            title="Followers"
            value="48"
            description="People who follow you"
          />
          <StatCard 
            icon={ShoppingCart}
            title="Whishlist"
            value="1,234"
            description="Total orders this month"
          />
          <StatCard 
            icon={Clock}
            title="Time on App"
            value="78%"
            description="User engagement rate"
          />
           <StatCard 
        icon={Star}
        title="Average Rating"
        value="4.8"
        description="Overall user satisfaction score"
      />
      <StatCard 
        icon={Heart}
        title="Likes"
        value="3,872"
        description="Total likes received this month"
      />
      <StatCard 
        icon={Wallet}
        title="Expenses"
        value="$8,459"
        description="Total expenses this month"
      />
      <StatCard 
        icon={WalletMinimal}
        title="Refunds"
        value="$3,000"
        description="Total expenses this month"
      />
        </div>
      </div>
                {/* Second Donut Chart */}
                <div className=" p-6 m-5 rounded-lg bg-[#0D182E]">
            <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Outgoings</h2>
            <div className="h-px w-full bg-slate-700 mb-4" />
            
            <div className="h-[400px] relative">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={secondDonutData}
                    innerRadius="60%"
                    outerRadius="80%"
                    dataKey="value"
                  >
                    {secondDonutData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={SECOND_COLORS[index]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [`${value}%`, '']}
                    contentStyle={{ 
                      background: '#1E2A44', 
                      border: 'none', 
                      borderRadius: '4px',
                      fontFamily: 'GeneralSansRegular'
                    }}
                    itemStyle={{ color: '#65D4B0' }}
                  />
                  <Legend 
                    verticalAlign="bottom"
                    formatter={(value, entry) => (
                      <span className="text-slate-300 font-['GeneralSansRegular']">{value}</span>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Resturants 312</div>
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Clothings 98</div>
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Others 3</div>
                
              </div>
            </div>
          </div>
          <div className="w-95 p-6 m-5 rounded-lg bg-[#0D182E]">
      <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Reviews and Referrals</h2>
      <div className="h-px w-full bg-slate-700 mb-4" />
      
      <div className="mb-4 flex gap-2">
        {['1 Year', '1 Month', '1 Week'].map((range) => (
          <button
            key={range}
            onClick={() => setTimeRange(range)}
            className={`px-3 py-1 rounded font-['GeneralSansMedium'] ${
              timeRange === range
                ? 'bg-emerald-400 text-slate-900'
                : 'bg-slate-800 text-slate-300 hover:bg-slate-700'
            }`}
          >
            {range}
          </button>
        ))}
      </div>
      
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={filterData(timeRange)}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorReviews" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#65D4B0" stopOpacity={0.8}/>
                <stop offset="95.55%" stopColor="#65D4B0" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorReferrals" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#DC3DF7" stopOpacity={0.8}/>
                <stop offset="95.55%" stopColor="#DC3DF7" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid 
              horizontal={true}
              vertical={false}
              stroke="#1E2A44"
            />
            <XAxis
              dataKey="date"
              tickFormatter={formatDate}
              type="number"
              domain={['dataMin', 'dataMax']}
              stroke="#4B5563"
              tick={{ fontFamily: 'GeneralSansRegular' }}
            />
            <YAxis 
              stroke="#4B5563"
              tick={{ fontFamily: 'GeneralSansRegular' }}
            />
            <Tooltip
              labelFormatter={formatTooltipDate}
              formatter={(value, name) => [`${value}`, name]}
              contentStyle={{ 
                background: '#1E2A44', 
                border: 'none', 
                borderRadius: '4px',
                fontFamily: 'GeneralSansRegular'
              }}
              labelStyle={{ color: '#9CA3AF' }}
            />
            <Area
              type="monotone"
              dataKey="reviews"
              stroke="#65D4B0"
              fillOpacity={1}
              fill="url(#colorReviews)"
            />
            <Area
              type="monotone"
              dataKey="referrals"
              stroke="#DC3DF7"
              fillOpacity={1}
              fill="url(#colorReferrals)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>

          {/* First Donut Chart */}
          <div className="w-95 p-6 m-6 rounded-lg bg-[#0D182E]">
            <h2 className="text-2xl font-['VerminViva'] text-slate-100 mb-4">Finances</h2>
            <div className="h-px w-full bg-slate-700 mb-4" />
            
            <div className="h-[400px] relative">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={donutData}
                    innerRadius="60%"
                    outerRadius="80%"
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {donutData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [`${value}%`, '']}
                    contentStyle={{ 
                      background: '#1E2A44', 
                      border: 'none', 
                      borderRadius: '4px',
                      fontFamily: 'GeneralSansRegular'
                    }}
                    itemStyle={{ color: '#65D4B0' }}
                  />
                  <Legend 
                    verticalAlign="bottom"
                    formatter={(value, entry) => (
                      <span className="text-slate-300 font-['GeneralSansRegular']">{value}</span>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Revenue</div>
                <div className="text-3xl font-['GeneralSansBold'] text-slate-100">$10000</div>
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Spent</div>
                <div className="text-3xl font-['GeneralSansBold'] text-slate-100">$1000</div>
              </div>
            </div>
          </div>
      
      <div className="pt-5 px-6 pb-2">
        <section className="mb-8 bg-[#0D182E] rounded-lg shadow-md p-4 sm:p-6">
          <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-white mb-4">Latest Experiences</h2>
          <div className="flex space-x-4 overflow-x-auto scrollbar-hide">
            {businessData.map((business, index) => (
              <BusinessCard
                key={index}
                image={business.image}
                title={business.title}
              />
            ))}
          </div>
        </section>
              {/* Review History Section */}
              <section className="bg-[#0D182E] rounded-lg shadow-md p-4 sm:p-6">
          <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-white mb-4">My Reviews</h2>
          <div className="flex space-x-4 overflow-x-auto scrollbar-hide">
            {reviewData.map((review, index) => (
              <ReviewCard
                key={index}
                image={review.image}
                businessName={review.businessName}
                rating={review.rating}
                review={review.review}
              />
            ))}
          </div>
        </section>

      </div>
    </>
  );
};

export default UserHome;