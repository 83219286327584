import React, { useState } from 'react';
import { Search, ArrowUpDown, Calendar, Share2, QrCode, ChevronDown } from 'lucide-react';
import ProfileImage from './assets/images/profile.png';
import logo from "./assets/images/loogfinal.png";

const Referrals = () => {
  const initialCustomerData = [
    { 
      id: 1,
      backgroundColor: 'bg-[#527DE6]', 
      senderName: 'Kat Larsson',
      email: 'KatLarsson@gmail.com', 
      phone: '(469) 532 2382',
      location: 'NewYork,Ny',
      referrals: [
        {
          id: 'ref1',
          businessName: 'Artistic Tattoos',
          businessLocation: 'NewYork,Ny',
          businessLogo: logo,
          industry: "Accommodation/Food services",
          products: ["Tattoo Design", "Body Piercing"],
          expiryDate: "2024-12-31",
          qrCode: "qr-code-data-1",
          isShared: true
        },
        {
          id: 'ref2',
          businessName: 'Beauty Salon',
          businessLocation: 'NewYork,Ny',
          businessLogo: logo,
          industry: "Beauty Services",
          products: ["Hair Styling", "Makeup"],
          expiryDate: "2024-06-30",
          qrCode: "qr-code-data-2",
          isShared: true
        }
      ]
    },
    { 
      id: 2,
      backgroundColor: 'bg-[#65D4B0]', 
      senderName: 'Mike Johnson',
      email: 'Mikejohnson@gmail.com',
      phone: '(469) 532 2383',
      location: 'NewYork,Ny',
      referrals: [] // No shared referrals
    },
    { 
      id: 3,
      backgroundColor: 'bg-[#DB005F]',
      senderName: 'Sarah Smith',
      email: 'sarah@contoso.com',
      phone: '(469) 532 2384',
      location: 'NewYork,Ny',
      referrals: [
        {
          id: 'ref3',
          businessName: 'Creative Ink',
          businessLocation: 'NewYork,Ny',
          businessLogo: logo,
          industry: "Retail Trade",
          products: ["Custom Tattoos", "Art Prints"],
          expiryDate: "2024-09-30",
          qrCode: "qr-code-data-3",
          isShared: true
        }
      ]
    }
  ];

  // State management
  const [customerData, setCustomerData] = useState(initialCustomerData);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [expandedReferrals, setExpandedReferrals] = useState({});

  // Toggle referrals dropdown
  const toggleReferrals = (customerId) => {
    setExpandedReferrals(prev => ({
      ...prev,
      [customerId]: !prev[customerId]
    }));
  };

  // Date filtering and sorting functions remain the same
  const isWithinDateRange = (dateString, filter) => {
    if (!dateString) return true;
    const date = new Date(dateString);
    const now = new Date();
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const oneYear = 365 * 24 * 60 * 60 * 1000;

    switch (filter) {
      case 'week':
        return (now - date) <= oneWeek;
      case 'month':
        return (now - date) <= oneMonth;
      case 'year':
        return (now - date) <= oneYear;
      default:
        return true;
    }
  };

  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...customerData];

    switch (option) {
      case 'name-asc':
        sortedData.sort((a, b) => a.senderName.localeCompare(b.senderName));
        break;
      case 'name-desc':
        sortedData.sort((a, b) => b.senderName.localeCompare(a.senderName));
        break;
      default:
        break;
    }

    setCustomerData(sortedData);
  };

  const filteredCustomers = customerData.filter(customer => {
    const searchTerm = nameFilter.toLowerCase();
    return (
      (!searchTerm ||
        customer.senderName.toLowerCase().includes(searchTerm) ||
        customer.email.toLowerCase().includes(searchTerm))
      && isWithinDateRange(customer.referrals[0]?.expiryDate, dateFilter)
    );
  });


  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      {/* Filters Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Search Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search Follower
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Sort By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="">Select Sort Option</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="business-asc">Business (A-Z)</option>
              <option value="business-desc">Business (Z-A)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="all">All Time</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-white mb-4">My Followers</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="hidden sm:table-header-group">
              <tr className="bg-[#1a2942]">
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Followers</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Referrals</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Business Details</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Industry</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Products & Services</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Expiry</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">QR Code</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {filteredCustomers.map((customer) => (
                <React.Fragment key={customer.id}>
                  <tr className="hover:bg-[#1a2942] flex flex-col sm:table-row">
                    <td className="px-4 sm:px-6 py-4">
                      <div className="flex items-center space-x-4">
                        <div className={`w-10 h-10 transform rotate-45 border border-[#1A2E56] rounded-[10px] overflow-hidden ${customer.backgroundColor}`}>
                          <div className="w-full h-full -rotate-45 overflow-hidden">
                            <img 
                              src={ProfileImage} 
                              alt="Profile" 
                              className="w-[130%] h-[105%] object-contain absolute" 
                            />
                          </div>
                        </div>
                        <div>
                          <p className="font-['GeneralSansRegular'] text-white">{customer.senderName}</p>
                          <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{customer.email}</p>
                          <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{customer.location}</p>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 sm:px-6 py-2 sm:py-4">
                      <button 
                        onClick={() => toggleReferrals(customer.id)}
                        className="flex items-center space-x-2 text-[#3DE9DC] hover:text-[#2bc9bd]"
                      >
                        <span className="font-['GeneralSansRegular']">
                          {customer.referrals.length} Referrals
                        </span>
                        <ChevronDown 
                          className={`transform transition-transform ${expandedReferrals[customer.id] ? 'rotate-180' : ''}`} 
                          size={16}
                        />
                      </button>
                    </td>
                    <td className="px-4 sm:px-6 py-2 sm:py-4" colSpan="5">
                      {customer.referrals.length === 0 && (
                        <span className="text-[#64718C] font-['GeneralSansRegular']">No referrals shared</span>
                      )}
                    </td>
                  </tr>
                  {expandedReferrals[customer.id] && customer.referrals.map((referral) => (
                    <tr key={referral.id} className="bg-[#1a2942] bg-opacity-50">
                      <td className="px-4 sm:px-6 py-2 sm:py-4"></td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4"></td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">
                        <div className="flex items-center space-x-3">
                          <div className="w-10 h-10 rounded-lg overflow-hidden">
                            <img 
                              src={referral.businessLogo} 
                              alt={`${referral.businessName} logo`}
                              className="w-full h-full object-contain"
                            />
                          </div>
                          <div>
                            <span className="font-['GeneralSansRegular'] text-white">{referral.businessName}</span>
                            <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{referral.businessLocation}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">
                        <span className="font-['GeneralSansRegular'] text-white">{referral.industry}</span>
                      </td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">
                        <div className="font-['GeneralSansRegular'] text-white">
                          {referral.products.map((product, index) => (
                            <div key={index}>{product}</div>
                          ))}
                        </div>
                      </td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">
                        <span className="font-['GeneralSansRegular'] text-white">
                          {new Date(referral.expiryDate).toLocaleDateString()}
                        </span>
                      </td>
                      <td className="px-4 sm:px-6 py-2 sm:py-4">
                        <button className="p-2 hover:bg-[#1A2E56] rounded-lg transition-colors">
                          <QrCode className="text-white" size={20} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {filteredCustomers.length === 0 && (
            <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
              No followers found matching the selected filters.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Referrals;