import React, { useState } from 'react';
import { Search, ArrowUpDown, Calendar, Share2, QrCode } from 'lucide-react';
import logo from "./assets/images/loogfinal.png";

const Referrals = () => {
  const initialCustomerData = [
    { 
      id: 1,
      receiverName: 'John Doe',
      businessName: 'Artistic Tattoos',
      businesslocation:'NewYork,Ny',
      businessLogo: logo,
      email: 'hamzaaarfan@gmail.com', 
      phone: '(469) 532 2382',
      date: '2024-01-15',
      expiry: '2024-06-15',
      status: 'redeemed',
      cashback:"5%"
    },
    { 
      id: 2,
      receiverName: 'Emma Wilson',
      businessName: 'Ink Masters',
       businesslocation:'NewYork,Ny',
      businessLogo: logo,
      email: 'hamzaaarfan@gmail.com',
      phone: '(469) 532 2383',
      date: '2024-02-20',
      expiry: '2024-07-20',
      status: 'waiting for payment',
      cashback:'20%'
    },
    { 
      id: 3,
      receiverName: 'Alex Brown',
      businessName: 'Creative Ink',
      businesslocation:'NewYork,Ny',
      businessLogo: logo,
      email: 'hamzaaarfan@gmail.com',
      phone: '(469) 532 2384',
      date: '2024-03-10',
      expiry: '2024-08-10',
      status: 'pending',
      cashback: '10%'
    }
  ];

  // State management
  const [customerData, setCustomerData] = useState(initialCustomerData);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('all');

  // Helper function to get status badge color
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'redeemed':
        return 'bg-[#527DE6]';
      case 'waiting for payment':
        return 'bg-[#DB005F]';
      case 'pending':
        return 'bg-[#65D4B0]';
      default:
        return 'bg-[#DB005F]';
    }
  };

  // Rest of the helper functions remain the same
  const isWithinDateRange = (dateString, filter) => {
    if (!dateString) return true;
    const date = new Date(dateString);
    const now = new Date();
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const oneYear = 365 * 24 * 60 * 60 * 1000;

    switch (filter) {
      case 'week':
        return (now - date) <= oneWeek;
      case 'month':
        return (now - date) <= oneMonth;
      case 'year':
        return (now - date) <= oneYear;
      default:
        return true;
    }
  };

  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...customerData];

    switch (option) {
      case 'name-asc':
        sortedData.sort((a, b) => (a.senderName || '').localeCompare(b.senderName || ''));
        break;
      case 'name-desc':
        sortedData.sort((a, b) => (b.senderName || '').localeCompare(a.senderName || ''));
        break;
      case 'business-asc':
        sortedData.sort((a, b) => (a.businessName || '').localeCompare(b.businessName || ''));
        break;
      case 'business-desc':
        sortedData.sort((a, b) => (b.businessName || '').localeCompare(a.businessName || ''));
        break;
      default:
        break;
    }

    setCustomerData(sortedData);
  };

  const filteredCustomers = customerData.filter(customer => {
    const searchTerm = nameFilter.toLowerCase();
    return (
      (!searchTerm ||
        (customer.senderName?.toLowerCase()?.includes(searchTerm) ||
         customer.receiverName?.toLowerCase()?.includes(searchTerm) ||
         customer.businessName?.toLowerCase()?.includes(searchTerm))) &&
      isWithinDateRange(customer.date, dateFilter)
    );
  });

  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      {/* Filters Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Search Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Sort Options */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Sort By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="">Select Sort Option</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="business-asc">Business (A-Z)</option>
              <option value="business-desc">Business (Z-A)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="all">All Time</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>

      {/* Referrals Table */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['VerminViva'] text-white mb-4"> My Referrals</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="hidden sm:table-header-group">
              <tr className="bg-[#1a2942]">
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Receiver</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Business</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Expiry</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">CashBack</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Status</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {filteredCustomers.map((customer) => (
                <tr key={customer.id} className="hover:bg-[#1a2942] flex flex-col sm:table-row mb-4 sm:mb-0">
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">{customer.receiverName || 'N/A'}</span>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-lg overflow-hidden">
                        <img 
                          src={customer.businessLogo} 
                          alt={`${customer.businessName} logo`}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <span className="font-['GeneralSansRegular'] text-white">{customer.businessName || 'N/A'}</span>
                    </div>
                    <p className="font-['GeneralSansRegular'] text-xs ml-14 text-white">{customer.businesslocation }</p>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <span className="font-['GeneralSansRegular'] text-white">
                      {customer.expiry ? new Date(customer.expiry).toLocaleDateString() : 'N/A'}
                    </span>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">{customer.cashback || 'N/A'}</span>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <div className="flex space-x-3">
                      <button className="p-2 hover:bg-[#1A2E56] rounded-lg transition-colors">
                        <QrCode className="text-white" size={20} />
                      </button>
                      <button className="p-2 hover:bg-[#1A2E56] rounded-lg transition-colors">
                        <Share2 className="text-white" size={20} />
                      </button>
                    </div>
                  </td>
                  <td className="px-4 sm:px-6 py-2 sm:py-4">
                    <span className={`px-3 py-1 rounded-full font-['GeneralSansRegular'] text-white text-sm ${getStatusColor(customer.status)}`}>
                      {customer.status || 'N/A'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* No Results Message */}
          {filteredCustomers.length === 0 && (
            <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
              No referrals found matching the selected filters.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Referrals;