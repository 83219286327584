import React, { useState } from 'react';
import { Calendar, Filter, FileText, Search, User } from 'lucide-react';

const InvoiceHistB = () => {
  const [typeFilter, setTypeFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [businessFilter, setBusinessFilter] = useState('');
  const [customerFilter, setCustomerFilter] = useState('');

  const invoiceData = [
    { 
      id: 'INV-001',
      type: 'Referral',
      date: '2024-01-15',
      amount: '$250.00',
      status: 'Paid',
      businessName: 'Acme Corp',
      customerName: 'John Smith',
      backgroundColor: 'bg-[#527DE6]'
    },
    { 
      id: 'INV-002',
      type: 'Review',
      date: '2024-02-20',
      amount: '$175.50',
      status: 'Pending',
      businessName: 'TechStart Inc',
      customerName: 'Sarah Johnson',
      backgroundColor: 'bg-[#65D4B0]'
    },
    { 
      id: 'INV-003',
      type: 'Referral',
      date: '2024-03-10',
      amount: '$300.75',
      status: 'Paid',
      businessName: 'Global Services',
      customerName: 'Mike Wilson',
      backgroundColor: 'bg-[#DB005F]'
    },
    { 
      id: 'INV-004',
      type: 'Review',
      date: '2024-04-05',
      amount: '$225.25',
      status: 'Overdue',
      businessName: 'Acme Corp',
      customerName: 'Emily Brown',
      backgroundColor: 'bg-[#FE8705]'
    },
    { 
      id: 'INV-005',
      type: 'Referral',
      date: '2024-05-22',
      amount: '$190.00',
      status: 'Pending',
      businessName: 'TechStart Inc',
      customerName: 'David Lee',
      backgroundColor: 'bg-[#DC3DF7]'
    }
  ];

  // Filter invoices based on type, date, business name, and customer name
  const filteredInvoices = invoiceData.filter(invoice => 
    (typeFilter === '' || invoice.type === typeFilter) &&
    (dateFilter === '' || invoice.date.startsWith(dateFilter)) &&
    (businessFilter === '' || invoice.businessName.toLowerCase().includes(businessFilter.toLowerCase())) &&
    (customerFilter === '' || invoice.customerName.toLowerCase().includes(customerFilter.toLowerCase()))
  );

  return (
    <div className="my-4 sm:my-20 mx-4 sm:mx-10">
      {/* Header */}
      

      {/* Filters */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Customer Name Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search Customer
          </label>
          <div className="relative">
            <input
              type="text"
              value={customerFilter}
              onChange={(e) => setCustomerFilter(e.target.value)}
              placeholder="Search by customer name"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <User className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Business Name Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search Business
          </label>
          <div className="relative">
            <input
              type="text"
              value={businessFilter}
              onChange={(e) => setBusinessFilter(e.target.value)}
              placeholder="Search by business name"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Type Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Type
          </label>
          <div className="relative">
            <select 
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none"
            >
              <option value="">All Types</option>
              <option value="Referral">Referral</option>
              <option value="Review">Review</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Filter className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <input 
              type="month"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg"
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>

      {/* Invoice List */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-10 mt-5">
        {filteredInvoices.map((invoice, index) => (
          <div key={index} className="flex flex-col sm:flex-row sm:items-center mb-6 sm:mb-4 p-4 rounded-lg hover:bg-[#1A2E56] transition-colors">
            {/* Diamond Container */}
            <div className="flex items-center mb-3 sm:mb-0">
              <div className={`w-[45px] h-[45px] transform rotate-45 border border-[#1A2E56] rounded-[10px] overflow-hidden ${invoice.backgroundColor}`}>
                <div className="w-full h-full -rotate-45 flex items-center justify-center">
                  <FileText className="text-white" size={20} />
                </div>
              </div>

              {/* Invoice Details - Mobile Optimized */}
              <div className="ml-4 flex-1">
                <p className="text-base text-white font-['GeneralSansMedium']">{invoice.id}</p>
                <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{invoice.customerName}</p>
                <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{invoice.businessName}</p>
              </div>
            </div>

            {/* Additional Details */}
            <div className="flex-1 ml-0 sm:ml-4 pl-14 sm:pl-0">
              <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{invoice.type} Invoice</p>
              <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{invoice.date}</p>
            </div>

            {/* Invoice Amount and Status */}
            <div className="flex justify-between sm:flex-col sm:items-end mt-3 sm:mt-0 pl-14 sm:pl-0">
              <p className="text-base text-white font-['GeneralSansMedium']">{invoice.amount}</p>
              <p className={`text-sm font-['GeneralSansRegular'] ${
                invoice.status === 'Paid' ? 'text-[#65D4B0]' : 
                invoice.status === 'Pending' ? 'text-[#FE8705]' : 
                'text-[#DB005F]'
              }`}>
                {invoice.status}
              </p>
            </div>
          </div>
        ))}

        {/* No Results Message */}
        {filteredInvoices.length === 0 && (
          <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
            No invoices found matching the selected filters.
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceHistB;