import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';
import FacebookIcon from './assets/images/facebook.png';
import AppleIcon from './assets/images/apple.png';
import { LogIn } from 'lucide-react';
import { useGoogleLogin } from '@react-oauth/google';

const SignIn1 = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSuccess = async (codeResponse) => {
    console.log(codeResponse);
    setIsLoading(true);
    setError('');
    
    try {

      const { code } = codeResponse;
      
      // Make API call to your backend
      const response = await fetch('https://superadmin.reviewpay.com.au/api/auth/social/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code })
      });

      const data = await response.json();

      if (response.ok) {
        if (data.access_token) {
          localStorage.setItem('accessToken', data.access_token);
        }
        if (data.refresh_token) {
          localStorage.setItem('refreshToken', data.refresh_token);
        }

        // Handle role-based navigation
        switch(data.role) {
          case 'user':
            navigate('/UserDashboard');
            break;
          case 'business':
            navigate('/BusinessDashboard');
            break;
          default:
            setError('Unauthorized role');
        }
      } else {
        setError(data.message || 'Google authentication failed');
      }
    } catch (err) {
      setError('An error occurred during Google authentication. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const GoogleLogin = useGoogleLogin({
    onSuccess: handleSuccess,
    flow: "auth-code",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const formDataObj = new FormData();
      formDataObj.append('email', formData.email);
      formDataObj.append('password', formData.password);

      const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/login/', {
        method: 'POST',
        body: formDataObj
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('accessToken', data.access_token);
        localStorage.setItem('refreshToken', data.refresh_token);
        switch(data.role) {
          case 'business':
            navigate('/BusinessDashboard');
            break;
          case 'user':
            navigate('/UserDashboard');
            break;
          default:
            setError('Unauthorized role');
        }
      } else {
        setError(data.message || 'Invalid email or password');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
        <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
            </div>
        </div>
      </div>

      {/* Login Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-md border border-white/20 shadow-2xl">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Sign In</h2>
        
        {error && (
          <div className="text-pink-500 px-4 py-2 rounded-lg text-lg mb-4 font-[GeneralSansRegular]">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              required
              className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                       text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                       focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                       transition-all duration-300 placeholder:font-[GeneralSansRegular]"
            />
          </div>
          <div>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Password"
              required
              className="w-full px-4 py-3 bg-[#667d9c] border border-white/10 rounded-lg 
                       text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                       focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                       transition-all duration-300 placeholder:font-[GeneralSansRegular]"
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white 
                     py-3 px-4 rounded-lg font-['GeneralSansBold'] hover:opacity-90 
                     transition-all duration-300 shadow-lg hover:shadow-xl 
                     hover:scale-[1.02] disabled:opacity-70 disabled:hover:scale-100"
          >
            {isLoading ? 'Signing in...' : 'Sign In'}
          </button>
        </form>

        <div className="text-center mt-4">
          <button 
            onClick={() => navigate('/ForgotPassword')}
            className="text-white/70 hover:text-white transition-all duration-300 font-['GeneralSansRegular'] text-sm"
          >
            Forgot Password?
          </button>
        </div>
        <div className="relative mt-5 mb-6">
          <span className="block w-full h-px bg-white/30"></span>
        </div>

        <div className="space-y-3">
          <button 
            className="w-full flex items-center justify-center gap-x-3 py-3 
            bg-white/5 border border-white/10 rounded-lg 
            hover:bg-white/10 transition-all duration-300"
            onClick={()=>{GoogleLogin()}}
          >
            <svg className="w-5 h-5" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_17_40)">
                <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
                <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
                <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
                <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
              </g>
              <defs>
                <clipPath id="clip0_17_40">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-white font-['GeneralSansRegular']">Continue with Google</span>
          </button>

          <button 
            className="w-full flex items-center justify-center gap-x-3 py-3 
            bg-white/5 border border-white/10 rounded-lg 
            hover:bg-white/10 transition-all duration-300"
          >
            <img src={FacebookIcon} alt="Facebook" className="w-5 h-5" />
            <span className="text-white font-['GeneralSansRegular']">Continue with Facebook</span>
          </button>
          <button 
            className="w-full flex items-center justify-center gap-x-3 py-3 
            bg-white/5 border border-white/10 rounded-lg 
            hover:bg-white/10 transition-all duration-300"
          >
            <img src={AppleIcon} alt="Facebook" className="w-5 h-5" />
            <span className="text-white font-['GeneralSansRegular']">Continue with Apple</span>
          </button>
        </div>
      </div>

      <div className="mt-8 mb-8 text-center">
        <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
          Don't have an account?
        </span>
        <button 
          onClick={() => navigate('/ChooseRole')}
          className="text-white font-['GeneralSansMedium'] hover:underline"
        >
          Join now
        </button>
      </div>
    </div>
  );
};

export default SignIn1;