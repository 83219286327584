import React, { useState } from 'react';
import { 
  ArrowLeft, 
  ArrowRight, 
  CheckCircle, 
  X, 
  AlertCircle, 
  ExternalLink,
  ShieldCheck,
  Lock
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from "./assets/images/loogfinal.png";
import paypal from "./assets/images/paypal-icon.png";
import stripe from "./assets/images/stripe-logo.png";

const PaymentIntegration = () => {
  const [isPayPalModalOpen, setIsPayPalModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isPayPalConnected, setIsPayPalConnected] = useState(false);
  const navigate = useNavigate();

  const handleSuccessClose = () => {
    setIsSuccessModalOpen(false);
    setIsPayPalConnected(true);
  };

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center p-4 z-50 -mt-28">
        <div className="bg-[#182B53] rounded-xl p-8 w-full max-w-lg border border-white/20 relative shadow-2xl">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-white/60 hover:text-white/80"
          >
            <X className="w-5 h-5" />
          </button>
          {children}
        </div>
      </div>
    );
  };
  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4 mt-20"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
        </div>
      </div>

      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-5xl border border-white/20 shadow-2xl mb-7">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Payment Integration</h2>

        <div className="flex flex-col lg:flex-row gap-6 mb-8">
          {/* PayPal Container */}
          <div className="flex-1 backdrop-blur-md bg-white/5 border border-white/20 rounded-xl p-8 relative hover:bg-white/10 transition-all duration-300">
            {isPayPalConnected && (
              <div className="absolute -top-3 -right-3 bg-white/10 rounded-full backdrop-blur-md">
                <CheckCircle className="w-8 h-8 text-green-400" />
              </div>
            )}
            <div className="space-y-6">
              <div className="flex items-center space-x-4">
                <img src={paypal} alt="PayPal Logo" className="w-16 h-16 object-contain" />
                <div>
                  <h3 className="font-['GeneralSansMedium'] text-xl mb-1 text-white">PayPal</h3>
                  <p className="text-white/70">Global payment processing solution</p>
                </div>
              </div>
              
              <div className="space-y-4">
                <div className="flex items-start space-x-3">
                  <ShieldCheck className="w-5 h-5 text-white/70 mt-1" />
                  <p className="text-sm text-white/70">Secure payment processing with buyer and seller protection</p>
                </div>
                <div className="flex items-start space-x-3">
                  <Lock className="w-5 h-5 text-white/70 mt-1" />
                  <p className="text-sm text-white/70">Advanced fraud prevention and security features</p>
                </div>
              </div>

              <button
                onClick={() => setIsPayPalModalOpen(true)}
                className={`w-full py-3 px-4 rounded-lg border transition-all duration-300
                  ${isPayPalConnected 
                    ? 'border-green-400/50 text-green-400 bg-green-400/10'
                    : 'border-white/30 text-white hover:bg-white/10'
                  }`}
                disabled={isPayPalConnected}
              >
                {isPayPalConnected ? 'Connected' : 'Connect PayPal'}
              </button>
            </div>
          </div>

          {/* Stripe Container */}
          <div className="flex-1 backdrop-blur-md bg-white/5 border border-white/20 rounded-xl p-8 relative hover:bg-white/10 transition-all duration-300">
            <div className="space-y-6">
              <div className="flex items-center space-x-4">
                <img src={stripe} alt="Stripe Logo" className="w-16 h-16 object-contain" />
                <div>
                  <h3 className="font-['GeneralSansMedium'] text-xl mb-1 text-white">Stripe</h3>
                  <p className="text-white/70">Complete payments platform</p>
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex items-start space-x-3">
                  <ShieldCheck className="w-5 h-5 text-white/70 mt-1" />
                  <p className="text-sm text-white/70">Built-in fraud prevention and revenue optimization</p>
                </div>
                <div className="flex items-start space-x-3">
                  <Lock className="w-5 h-5 text-white/70 mt-1" />
                  <p className="text-sm text-white/70">PCI compliance and industry-leading security</p>
                </div>
              </div>

              <button
                className="w-full py-3 px-4 rounded-lg border border-white/30 text-white hover:bg-white/10 transition-all duration-300"
              >
                Connect Stripe
              </button>
            </div>
          </div>
        </div>

        {/* Terms Modal */}
        <Modal isOpen={isPayPalModalOpen} onClose={() => setIsPayPalModalOpen(false)}>
  <div className="flex items-center space-x-4 mb-8">
    <img src={paypal} alt="PayPal Logo" className="w-16 h-16 object-contain bg-white rounded-lg p-2" />
    <div>
      <h3 className="text-2xl font-['GeneralSansMedium'] text-white mb-1">Connect PayPal</h3>
      <p className="text-white/70">Complete account verification and setup</p>
    </div>
  </div>

  <div className="mb-8">
    <div className="bg-[#2C427A] rounded-xl p-6 mb-6 border border-white/10">
      <div className="flex items-start space-x-3">
        <AlertCircle className="w-6 h-6 text-white mt-0.5" />
        <div className="space-y-2">
          <h4 className="font-['GeneralSansMedium'] text-white">
            Secure Redirection
          </h4>
          <p className="text-sm text-white/70">
            You'll be securely redirected to PayPal to complete the connection process. 
            This ensures your credentials are handled safely.
          </p>
        </div>
      </div>
    </div>

    <div className="space-y-6 text-white/70">
      <div>
        <h4 className="font-['GeneralSansMedium'] text-white mb-3">
          By connecting your PayPal account, you agree to:
        </h4>
        <ul className="list-disc pl-6 space-y-3">
          <li>Share your PayPal business account information for verification</li>
          <li>Allow secure payment processing through your connected account</li>
          <li>Comply with PayPal's terms of service and merchant agreements</li>
          <li>Maintain accurate business and tax information</li>
          <li>Follow PayPal's acceptable use and privacy policies</li>
        </ul>
      </div>
      <a href="#" className="inline-flex items-center text-white hover:text-white/80">
        View PayPal's Terms of Service
        <ExternalLink className="w-4 h-4 ml-1" />
      </a>
    </div>
  </div>

  <div className="flex justify-end space-x-4">
    <button
      onClick={() => setIsPayPalModalOpen(false)}
      className="px-6 py-2 text-white/70 hover:text-white"
    >
      Cancel
    </button>
    <button
      onClick={() => {
        setIsPayPalModalOpen(false);
        setIsSuccessModalOpen(true);
      }}
      className="px-6 py-2 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-lg hover:opacity-90 transition-all duration-200 shadow-lg hover:shadow-xl hover:scale-[1.02]"
    >
      Continue to PayPal
    </button>
  </div>
</Modal>

        {/* Success Modal */}
        <Modal isOpen={isSuccessModalOpen} onClose={handleSuccessClose}>
  <div className="text-center">
    <div className="w-20 h-20 bg-green-400/20 rounded-full flex items-center justify-center mx-auto mb-6">
      <CheckCircle className="w-10 h-10 text-green-400" />
    </div>
    <img src={paypal} alt="PayPal Logo" className="h-10 object-contain mx-auto mb-6 bg-white rounded-lg p-2" />
    <h3 className="text-2xl font-['GeneralSansMedium'] text-white mb-3">
      PayPal Connected Successfully
    </h3>
    <p className="text-white/70 mb-6">
      Your PayPal business account has been successfully connected. You can now accept payments 
      through PayPal on your platform.
    </p>
    <div className="bg-[#2C427A] rounded-lg p-4 mb-8 text-left border border-white/10">
      <h4 className="font-['GeneralSansMedium'] text-white mb-2">Next Steps:</h4>
      <ul className="text-sm text-white/70 space-y-2">
        <li>• Complete your business profile</li>
        <li>• Set up your payment preferences</li>
        <li>• Review PayPal's merchant guidelines</li>
      </ul>
    </div>
    <button
      onClick={handleSuccessClose}
      className="px-8 py-3 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-lg hover:opacity-90 transition-all duration-200 shadow-lg hover:shadow-xl hover:scale-[1.02]"
    >
      Continue Setup
    </button>
  </div>
</Modal>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-12 pt-6 border-t border-white/20">
          <button
            type="button"
            onClick={() => navigate("/UserDashboard")}
            className="flex items-center px-6 py-3 border-2 border-white/30 text-white rounded-lg font-['GeneralSansMedium'] hover:bg-white/10 transition-all duration-200"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </button>
          {isPayPalConnected && (
            <button
              type="button"
              onClick={() => navigate('/UserDashboard')}
              className="flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 text-white rounded-lg font-['GeneralSansMedium'] hover:opacity-90 transition-all duration-200 shadow-lg hover:shadow-xl hover:scale-[1.02]"
            >
              Next Step
              <ArrowRight className="w-4 h-4 ml-2" />
            </button>
          )}
        </div>
      </div>

    </div>
  );
};

export default PaymentIntegration;