import React, { useState } from 'react';
import { ChevronLeft, Search, Upload, ChevronsRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const SettingsScreen = () => {
  const navigate = useNavigate();
  // State for form inputs and toggles
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [postcode, setPostcode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [profileVisibility, setProfileVisibility] = useState('Anyone');
  const [currentEmployment, setCurrentEmployment] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [identificationNumber, setIdentificationNumber] = useState('');
  const [previousEmployment, setPreviousEmployment] = useState('');

  // Handler functions
  const handleLinkProfessionalProfile = () => {
    // Implement logic to link professional profile
    console.log('Linking professional profile');
  };

  const handleUnlinkProfile = () => {
    // Implement logic to unlink profile
    console.log('Unlinking profile');
    // Clear professional profile fields
    setCurrentEmployment('');
    setBusinessName('');
    setIdentificationNumber('');
  };

  const handleAddPreviousEmployment = () => {
    // Implement logic to add previous employment
    console.log('Adding previous employment:', previousEmployment);
    // You might want to add this to a list of previous employments
    setPreviousEmployment(''); // Clear input after adding
  };

  const handleShowReviewHistory = () => {
    // Implement logic to show review history
    console.log('Showing review history');
  };

  const handleUnlinkReviewHistory = () => {
    // Implement logic to unlink review history
    console.log('Unlinking review history');
  };

  // Image pick handler (mock function)
  const pickImage = () => {
    alert('Image picker would be implemented here');
  };

  // Handle 2FA toggle with redirect
  const handle2FAToggle = () => {
    if (!is2FAEnabled) {
      navigate('/UserDashboard/settings/2fasettings');
    } else {
      setIs2FAEnabled(false);
    }
  };
  const handleChangePassword = () =>{
    navigate("ChangePassword");
  }
  const handleuserdetails  =()=>{
    navigate("UserDetail");
  }

  // Custom Toggle Component
  const Toggle = ({ isChecked, onToggle }) => {
    return (
      <div 
        className={`w-14 h-7 rounded-full p-1 cursor-pointer transition-colors duration-300 
        ${isChecked ? 'bg-[#04A6A8]' : 'bg-gray-400'}`}
        onClick={onToggle}
      >
        <div 
          className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 
          ${isChecked ? 'translate-x-7' : 'translate-x-0'}`}
        ></div>
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#4D6F8F] pb-20">
      {/* Header */}
      <div className="flex justify-between items-center p-4 pt-20 pb-10">
       
      </div>

      {/* Profile Information Section */}
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-6 font-['VerminViva']">Profile Information</h2>
        
        {/* Input Fields */}
        <div className="space-y-4">
          {/* First Name Input */}
          <div className="relative">
            <input 
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter first name"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">First Name</label>
          </div>

          {/* Last Name Input */}
          <div className="relative">
            <input 
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter last name"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Last Name</label>
          </div>
                    {/* Last Name Input */}
                    <div className="relative">
            <input 
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Email"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Email</label>
          </div>
        </div>
        <div className="relative">
          <input 
            type="date"
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            className="w-full mt-4 bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0] 
                       focus:outline-none cursor-pointer
                       [&::-webkit-calendar-picker-indicator]:invert 
                       [&::-webkit-calendar-picker-indicator]:brightness-0"
          />
          <label className="absolute top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Date of Birth</label>
        </div>

        {/* Gender Select */}
        <div className="relative">
          <select 
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className="w-full mt-4 bg-[#0D182E] border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
          >
            <option value="" className="text-[#959DB0]">Select Gender</option>
            <option value="male" className="text-white">Male</option>
            <option value="female" className="text-white">Female</option>
            <option value="other" className="text-white">Other</option>
            <option value="prefer-not-to-say" className="text-white">Prefer not to say</option>
          </select>
          <label className="absolute top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Gender</label>
        </div>

        {/* Postcode Input */}
        <div className="relative">
          <input 
            type="text"
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
            placeholder="Enter postcode"
            className="w-full mt-4 bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
          />
          <label className="absolute top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Postcode</label>
        </div>

        {/* Phone Number Input */}
        <div className="relative">
          <input 
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
            className="w-full mt-4 bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
          />
          <label className="absolute top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Phone Number</label>
        </div>

        {/* Profile Image Upload */}
        <div className="mt-6">
          <h3 className="text-white text-lg font-bold mb-6 font-['VerminViva']">Add Profile Image</h3>
          <div 
            onClick={pickImage}
            className="border border-[#E2E8F0] rounded-2xl h-44 flex flex-col justify-center items-center cursor-pointer"
          >
            <div className="mb-4">
              <Upload className="w-12 h-12 text-white" />
            </div>
            <p className="text-white text-base font-['GeneralSansMedium'] mb-1">
              Click to upload <span className="text-[#959DB0] text-sm">or drag and drop</span>
            </p>
            <p className="text-[#959DB0] text-sm">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </div>
        </div>
      </div>
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
  <h2 className="text-white text-xl font-bold mb-6 font-['VerminViva']">Professional Profile</h2>
  
  {/* Current Employment */}
  <div className="relative mb-4">
    <input 
      type="text"
      value={currentEmployment}
      onChange={(e) => setCurrentEmployment(e.target.value)}
      placeholder="Enter current job title"
      className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
    />
    <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Current Employment</label>
  </div>
    {/* Business Name */}
    <div className="relative mb-4">
    <input 
      type="text"
      value={businessName}
      onChange={(e) => setBusinessName(e.target.value)}
      placeholder="Enter business name"
      className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
    />
    <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Business Name</label>
  </div>
  <div className="relative mb-4">
    <input 
      type="number"
      value={identificationNumber}
      onChange={(e) => setIdentificationNumber(e.target.value)}
      placeholder="Enter Identification Number"
      className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
    />
    <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Identification Number</label>
  </div>


          
          <div className="flex justify-between items-center py-3 border-b    border-[#959DB0]"
         >
          <span className="text-white text-base font-['GeneralSansRegular']">
            Link Professional Profile
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>

          
          <div className="flex justify-between items-center py-3  border-b    border-[#959DB0]"
         >
          <span className="text-white text-base font-['GeneralSansRegular']">
            Add Previous Employement
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>
        <div className="flex justify-between items-center py-3  border-b    border-[#959DB0]"
         >
          <span className="text-white text-base font-['GeneralSansRegular']">
           Show Review History
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>
        <div className="flex justify-between items-center py-3 mb-4 border-b    border-[#959DB0]"
         >
          <span className="text-white text-base font-['GeneralSansRegular']">
           Unlink Review History
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>
</div>

      {/* Account Security Section */}
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-4 font-['VerminViva']">Account Security</h2>
        
        {/* Two-Factor Authentication */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Enable Two-factor Authentication
          </span>
          <Toggle 
            isChecked={is2FAEnabled} 
            onToggle={handle2FAToggle} 
          />
        </div>

        {/* Change Password */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]"
        onClick={handleChangePassword}>
          <span className="text-white text-base font-['GeneralSansRegular']">
            Change Password
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>

        {/* Manage Connected Accounts */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Manage Connected Accounts
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>

        {/* Profile Visibility */}
        <div className="flex justify-between items-center py-3">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Who can view your profile
          </span>
          <select 
            value={profileVisibility}
            onChange={(e) => setProfileVisibility(e.target.value)}
            className="bg-[#0D182E] text-white border-none"
          >
            <option value="Anyone">Anyone</option>
            <option value="Private">Private</option>
          </select>
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-4 font-['VerminViva']">Account Details</h2>
        


        {/* Change Password */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]"
        onClick={handleuserdetails}>
          <span className="text-white text-base font-['GeneralSansRegular']">
            User Details
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>
        </div>

      {/* Notifications Section */}
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-4 font-['VerminViva']">Notifications</h2>
        
        {/* Push Notifications */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Push Notifications
          </span>
          <Toggle 
            isChecked={pushNotifications} 
            onToggle={() => setPushNotifications(!pushNotifications)} 
          />
        </div>

        {/* Email Notifications */}
        <div className="flex justify-between items-center py-3">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Email Notifications
          </span>
          <Toggle 
            isChecked={emailNotifications} 
            onToggle={() => setEmailNotifications(!emailNotifications)} 
          />
        </div>
      </div>

      {/* Logout Button */}
      <button className="bg-[#B7BDCA] mx-4 p-4 rounded-lg">
        <span className="text-[#0D182E] text-base font-bold font-['GeneralSansMedium']">
          Logout
        </span>
      </button>
    </div>
  );
};

export default SettingsScreen;